const defaultState = {
    message: "Hey there"
};

const home = (state: any = defaultState, action: any) => {
    switch (action.type) {
        case 'SAY_HELLO':
            return {
                ...state,
                message: 'SAY_HELLO'
            };
        case 'SAY_WORLD':
            return {
                ...state,
                message: 'SAY_WORLD'
            };
        default:
            return state;
    }
};

export default home;
