import {Header, State} from "../../store/state";
import {connect} from "react-redux";
import BlogsComponent from "../../components/home/blogs/blogs";

export interface BlogsStateProps {
    header: Header;
}

const mapStateToProps = (
    state: State,
): BlogsStateProps => ({
    header: state.header,
});

const BlogsContainer = connect(
    mapStateToProps,
)(BlogsComponent);

export default BlogsContainer;
