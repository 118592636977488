import React, {useEffect, useLayoutEffect, useState} from 'react';
import "react-multi-carousel/lib/styles.css";
import axios, {AxiosResponse} from "axios";
import {Button, Card, CardActions, CardContent, Collapse, Grid, makeStyles, Typography} from "@material-ui/core";
import {ButtonBack, ButtonNext, CarouselProvider, Slide, Slider} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import {ArrowBack, ArrowForward} from "@material-ui/icons";
import {BookResponse} from "../../../types/books";
import {BooksHomeStateProps} from "../../../containers/home/books";
import {translateText} from "../../../utils/utils";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        marginBottom: theme.spacing(5),
        minWidth: 375,
    },
    booksSection: {
        marginTop: theme.spacing(3),
        minWidth: 375,
    },
    logo: {
        marginTop: theme.spacing(10),
        width: 60,
        [theme.breakpoints.down("xs")]: {
            width: 40,
        },
        [theme.breakpoints.only("xs")]: {
            width: 40,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: 40,
        },
        [theme.breakpoints.only("sm")]: {
            width: 40,
        },
    },
    title: {
        marginTop: theme.spacing(3),
        fontFamily: "ArcherProBold",
        fontSize: "2.5em",
        letterSpacing: '1px',
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2.5em",
        },
    },
    dividerGrid: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: theme.spacing(3),
        },
    },
    card: {
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(4),
        // height: 650
    },
    cardTitle: {
        marginTop: theme.spacing(2),
        fontSize: "1.6em",
        letterSpacing: "1px",
        color: theme.palette.primary.dark,
        textAlign: "center",
        fontFamily: "ArcherProLight",
        wordBreak: "break-word",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.5em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.5em",
        },
    },
    cardContent: {
        // height: 120
    },
    cardDescription: {
        fontSize: "1.2em",
        color: theme.palette.primary.dark,
        [theme.breakpoints.only("lg")]: {
            fontSize: "0.9em"
        }
    },
    cardAction: {
        // marginRight: "auto",
        // [theme.breakpoints.down("xs")]: {
        //     margin: "auto",
        // },
        // [theme.breakpoints.only("xs")]: {
        //     margin: "auto",
        // },
        // [theme.breakpoints.between("xs", "sm")]: {
        //     margin: "auto",
        // },
        // [theme.breakpoints.only("sm")]: {
        //     margin: "auto",
        // },
    },
    cardButton: {
        fontFamily: "ArcherPro",
        fontSize: "1em",
        letterSpacing: "1px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.8em",
            textAlign: "center",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.8em",
            textAlign: "center",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.8em",
            textAlign: "center",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.8em",
            textAlign: "center",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1em",
        },
    },
    media: {
        height: 420,
        [theme.breakpoints.only("lg")]: {
            height: 420
        }
    },
    slider: {
        height: 700,
        [theme.breakpoints.down("xs")]: {
            height: 600,
        },
        [theme.breakpoints.only("xs")]: {
            height: 600,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            height: 600,
        },
        [theme.breakpoints.only("sm")]: {
            height: 600,
        },
    },
    slideButton: {
        background: "none",
        border: "none",
        marginTop: theme.spacing(3),
    },
    button: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        width: 350,
        padding: theme.spacing(1.3)
    },
    buttonText: {
        fontFamily: "ArcherProBold",
        fontSize: "1.3em",
        letterSpacing: "1px",
        marginLeft: theme.spacing(1),
        textTransform: "none",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
            textAlign: "center",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
            textAlign: "center",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
            textAlign: "center",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
            textAlign: "center",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.3em",
        },
    },
    expand: {
        transform: 'rotate(0deg)',
        marginRight: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    dots: {
        color: theme.palette.primary.main,
    },
    carousel: {
        position: "relative"
    },
    buttonBack: {
        margin: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: 0,
    },
    buttonNext: {
        margin: 0,
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        right: 0,
    },
    buttonArrow: {
        backgroundColor: "white",
        color: theme.palette.primary.main,
        border: "none"
    },
    link: {
        textDecoration: "none",
    },
}));

interface BookCardData {
    book: BookResponse,
    index: number,
    language: string,
}

const Books = (props: BooksHomeStateProps) => {
    const {
        language,
    } = props;

    const [books, setBooks] = useState(new Array<BookResponse>());

    useEffect(() => {
        axios.get(`/api/Books`, {
            headers: {
                "ApiKey": "SVAM",
                "Access-Control-Allow-Origin": "*",
                "Access-Control-Allow-Headers": "Origin, X-Requested-With, Content-Type, Accept",
            }
        }).then((resp: AxiosResponse<Array<BookResponse>>) => {
            console.log(resp.data);
            setBooks(resp.data);
        }).catch(() => {
            setBooks([]);
        })
    }, []);

    function useNumberOfSlides() {
        const [size, setSize] = useState([0, 0]);
        useLayoutEffect(() => {
            function updateSize() {
                setSize([window.innerWidth, window.innerHeight]);
            }
            window.addEventListener('resize', updateSize);
            updateSize();
            return () => window.removeEventListener('resize', updateSize);
        }, []);
        return size;
    }

    const [width, height] = useNumberOfSlides();
    console.log(width);
    const classes = useStyles();

    const getNumberOfSlides = (): number => {
        if (width > 1580) {
            return 3
        }
        if (width > 1000) {
            return 2
        }
        if (width > 0) {
            return 1
        }
        return 3;
    };

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg" alt="divider"/>
                    <Typography variant="h5" component="div"className={classes.title}>
                        {
                            translateText(
                                language,
                                "Books",
                                "पुस्तके"
                            )
                        }
                    </Typography>
                </Grid>
                <Grid item xs={12} className={classes.dividerGrid}/>

                <Grid item sm={2} />
                <Grid item xs={12} sm={8} md={8} lg={8} className={classes.booksSection}>
                    <CarouselProvider
                        naturalSlideWidth={10}
                        naturalSlideHeight={10}
                        totalSlides={books.length}
                        visibleSlides={getNumberOfSlides()}
                        infinite={true}
                        step={1}
                        className={classes.carousel}
                    >
                        <Slider className={classes.slider}>
                            {
                                books.map((book: BookResponse, index) => (
                                    <Slide index={index} >
                                        <BookCard language={language} book={book} index={index} />
                                    </Slide>
                                ))
                            }
                        </Slider>
                        <div className={classes.buttonBack}>
                            <ButtonBack className={classes.buttonArrow}><ArrowBack /></ButtonBack>
                        </div>
                        <div className={classes.buttonNext}>
                            <ButtonNext className={classes.buttonArrow}><ArrowForward /></ButtonNext>
                        </div>
                    </CarouselProvider>
                </Grid>
                <Grid item sm={2} />

                <Grid component="div" item xs={12}>
                    <Button href="/books" variant="outlined" color="primary" className={classes.button}>
                        <Typography component="span" variant="body1" color="primary" className={classes.buttonText}>
                            {
                                translateText(
                                    language,
                                    "Explore Books",
                                    "अधिक माहिती"
                                )
                            }

                        </Typography>
                    </Button>
                </Grid>
            </Grid>
        </>
    )
};

const BookCard = (bookCard: BookCardData) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Card className={classes.card} elevation={0}>
            <img src={bookCard.book.coverPageImage} className={classes.media} alt={bookCard.book.bookName}/>
            <CardContent className={classes.cardContent}>
                <Typography gutterBottom variant="body1" component="h2" className={classes.cardTitle}>
                    {
                        translateText(
                            bookCard.language,
                            bookCard.book.bookName || "",
                            bookCard.book.bookNameMarathi || ""
                        )
                    }
                </Typography>
            </CardContent>
            <CardActions style={{
                textAlign: "center",
                justifyContent: "center"
            }}>
                <Link to={`/books/${bookCard.book.bookId}`} className={classes.link}>
                    <Button className={classes.cardAction} color="primary" variant="text" endIcon={<ArrowForward />}>
                        <Typography component="span" variant="body2" style={{
                            verticalAlign: "middle",
                            fontWeight: "bold"
                        }} className={classes.cardButton}>
                            {
                                translateText(
                                    bookCard.language,
                                    "View",
                                    "पहा"
                                )
                            }
                        </Typography>
                    </Button>
                </Link>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography variant="body2" component="p" className={classes.cardDescription} display="block">
                        {bookCard.book.shortDescription}
                    </Typography>
                </CardContent>
            </Collapse>
        </Card>
    )
};

export default Books;
