import React from 'react';
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import {HeroStateProps} from "../../../containers/home/hero";
import {translateText} from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
    header: {
        background: `url("../assets/img/Hero-banner-gurupornima-03.jpg")`,
        backgroundSize: "cover, cover",
        height: 950,
        minWidth: 375,
        // width: window.screen.width,
        position: "relative",
        //marginTop: 100,
        flexGrow: 1,
        [theme.breakpoints.down("xs")]: {
            height: 300,
        },
        [theme.breakpoints.only("xs")]: {
            height: 300,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            height: 300,
        },
        [theme.breakpoints.only("sm")]: {
            height: 500,
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: 500,
        },
        [theme.breakpoints.only("md")]: {
            height: 700,
        },
    },
    textSection: {
        width: "100%",
        textAlign: "center",
        marginTop: "15%",
        color: "#FFFFFF",
        flexGrow: 1,
        [theme.breakpoints.down("xs")]: {
            marginTop: "50%",
        },
        [theme.breakpoints.only("md")]: {
            marginTop: "20%",
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: "20%",
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: "40%",
        },
        [theme.breakpoints.only("lg")]: {
            marginTop: "15%",
        },
    },
    descriptionGrid: {
        paddingRight: 20,
        color: "#FFFFFF",
        margin: "auto",
        // verticalAlign: "middle",
        [theme.breakpoints.down("xs")]: {
            margin: 0,
            marginTop: "auto !important",
            paddingBottom: 20,
        },
        [theme.breakpoints.only("sm")]: {
            margin: 0,
            marginTop: "auto !important",
            paddingBottom: 20,
            paddingRight: 0,
        },
        [theme.breakpoints.between("sm", "xs")]: {
            margin: 0,
            marginTop: "auto !important",
            paddingBottom: 20,
            paddingRight: 0,
            verticalAlign: "down",
        },
        [theme.breakpoints.only("xs")]: {
            margin: 0,
            marginTop: "auto !important",
            paddingBottom: 20,
            paddingRight: 0,
            verticalAlign: "down",
        },
        [theme.breakpoints.between("sm", "md")]: {
            margin: "auto",
            verticalAlign: "middle",
        },
        [theme.breakpoints.only("md")]: {
            margin: "auto",
            verticalAlign: "middle",
        },
        [theme.breakpoints.between("md", "lg")]: {
            margin: "auto",
            verticalAlign: "middle",
        },
        [theme.breakpoints.only("lg")]: {
            margin: "auto",
            verticalAlign: "middle",
        },
    },
    title: {
        fontSize: "1.8em",
        fontFamily: 'ArcherProBold',
        letterSpacing: "0.1em",
        color: theme.palette.primary.dark,
        //textShadow: "2px 2px 4px #000000",
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "0.7em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.down("lg")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.4em",
        },
    },
    description: {
        marginTop: theme.spacing(3),
        fontSize: "1.3em",
        textAlign: "center",
        letterSpacing: "1px",
        color: theme.palette.primary.dark,
        // textShadow: "2px 2px 4px #000000",
        [theme.breakpoints.only("md")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: '0.6em',
        },
        [theme.breakpoints.only("xs")]: {
            display: "none"
        },
    },
    button: {
        // color: "white",
        // borderColor: "white",
        borderColor: theme.palette.primary.dark,
        marginTop: theme.spacing(5),
        letterSpacing: "1px",
        padding: "10px",
        width: 400,
        fontFamily: "ArcherProBold",
        [theme.breakpoints.only("xs")]: {
            width: 300,
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.only("sm")]: {
            width: 200,
        },
        [theme.breakpoints.only("md")]: {
            width: 300,
        },
    },
    buttonText: {
        fontFamily: "ArcherProBold",
        fontSize: "1em",
        color: theme.palette.primary.dark,
        // textShadow: "2px 2px 4px #000000",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.2em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.3em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "0.4em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1em",
        },
    }
}));

const HeroComponent = (props: HeroStateProps) => {
    const {
        header,
    } = props;

    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.header}>
                <Grid item sm={8} md={8} lg={8} />
                <Grid item xs={12} sm={4} md={4} lg={4} className={classes.descriptionGrid}>
                    <Typography className={classes.title} variant="body1" component="div">
                        {
                            translateText(
                                header.language,
                                '|| Shreevidya Akhand Mahayog ||',
                                '|| श्रीविद्या अखंडमहायोग ||'
                            )
                        }
                    </Typography>
                    <Typography variant="body1" component="div" className={classes.description}>
                        {
                            translateText(
                                header.language,
                                `
                                Shreevidyaakhandmahayog is the most ancient spiritual discipline of yoga. This discipline integrates all branches of
                        yoga - from Hathayog to Mantrayog to Bhaktiyog and more. The teachings of
                        Shreevidyaakhandmahayog have been passed on for eons. In the present day revered Shree Sadguru Shriniwasji Shamrao Katkar continues to
                        disseminate these teachings.
                                `,
                                `
                                श्रीविद्याअखंडमहायोग ही अध्यात्म मार्गातील अति प्राचीन अशी योग परंपरा आहे. हठ्योगापासून ते मंत्रयोगापर्यंत व इतरही सर्व साधनांना  एकात्मिक व समन्वित स्वरुपात प्रगट करते. युगा-युगा पासून चालत आलेली ही दिव्य बोध परंपरा सांप्रत काळी  श्री सद्गुरुदेव श्रीनिवासजी  काटकर पुढे नेत आहेत.
                                `
                            )
                        }
                    </Typography>
                    <Button variant="text" className={classes.button} href="/about" endIcon={<ArrowForward />}>
                        <Typography className={classes.buttonText} variant="button">
                            {
                                translateText(
                                    header.language,
                                    'Learn More',
                                    'अधिक माहिती'
                                )
                            }
                        </Typography>
                    </Button>
                </Grid>
            </Grid>
            {/*<Grid container className={classes.textSection}>*/}

            {/*</Grid>*/}
        </>
    )
};

export default HeroComponent;
