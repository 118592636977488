import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
// @ts-ignore
import ImageGallery from 'react-image-gallery';
import ContentLoader from 'react-content-loader';
import './details.scss';
import axios, {AxiosResponse} from "axios";
import {apiURL} from "../../../App";
import {convertImages, GalleryImage, Photo} from "../../../types/gallery";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minWidth: 375,
    },
    loader: {
        textAlign: "center"
    }
}));

export interface GalleryDetailsProps {
    index: number;
}

const GalleryDetails = ({ index }: GalleryDetailsProps) => {
    const [hasImages, setHasImages] = useState(false);

    const defaultImages: Array<GalleryImage> = [];

    const [images, setImages] = useState(defaultImages);

    useEffect(() => {
        axios.get(`${apiURL}/api/GetPhotosByAlbum/${index}`, {
            headers: {
                "ApiKey": "SVAM",
            }
        }).then((result: AxiosResponse<Array<Photo>>) => {
            setImages(convertImages(result.data));
            setHasImages(true);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const classes = useStyles();

    return (
        <div className={classes.root}>
            {
                hasImages ? (
                    <ImageGallery items={images} />
                ) : (
                    <div className={classes.loader}>
                        <ContentLoader
                            viewBox="0 0 400 160"
                            height={160}
                            width={400}
                            backgroundColor="transparent"
                        >
                            <circle cx="150" cy="86" r="8" />
                            <circle cx="194" cy="86" r="8" />
                            <circle cx="238" cy="86" r="8" />
                        </ContentLoader>
                    </div>
                )
            }
        </div>
    )
};

export default GalleryDetails;
