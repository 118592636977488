import {connect} from "react-redux";
import OrderForm from "../components/order-form/order-form";
import {State} from '../store/state';
import {CloseOrderForm} from '../actions/order-form';
import {Dispatch} from "redux";

export interface OrderFormInputProps {
  bookId: number;
  bookName: string;
}

export interface OrderFormStateProps {
  show: boolean;
  bookId: number;
  bookName: string;
}

export interface OrderFormDispatchProps {
  closeOrderForm: () => void;
}

const mapStateToProps = (state: State, input: OrderFormInputProps): OrderFormStateProps => ({
  show: state.orderForm.show,
  bookId: input.bookId,
  bookName: input.bookName,
});

const mapDispatchToProps = (dispatch: Dispatch): OrderFormDispatchProps => ({
  closeOrderForm: () => dispatch(CloseOrderForm())
});

const OrderFormContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderForm);

export default OrderFormContainer;
