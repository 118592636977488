import {Header, State} from "../store/state";
import {connect} from "react-redux";
import FooterComponent from "../components/footer/footer";

export interface FooterStateProps {
    header: Header;
}

const mapStateToProps = (state: State): FooterStateProps => ({
    header: state.header,
});

const FooterContainer = connect(
    mapStateToProps,
)(FooterComponent);

export default FooterContainer;
