import React, {useState} from 'react';
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import {ArrowForward} from '@material-ui/icons'
import {ArticleInfo, ArticlePart} from "./types";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        minWidth: 375,
    },
    grid: {
        textAlign: "justify",
        marginBottom: theme.spacing(6)
    },
    image: {
        width: "100%",
        height: 700,
        [theme.breakpoints.down("xs")]: {
            height: 200,
        },
        [theme.breakpoints.only("xs")]: {
            height: 200,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            height: 230,
        },
        [theme.breakpoints.only("sm")]: {
            height: 400,
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: 400,
        },
        [theme.breakpoints.only("md")]: {
            height: 450,
        },
        [theme.breakpoints.between("md", "lg")]: {
            height: 500,
        },
        [theme.breakpoints.only("lg")]: {
            height: 700,
        },
    },
    title: {
        marginTop: theme.spacing(5),
        fontFamily: "ArcherProBold",
        fontSize: "2em",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2em",
        },
    },
    publishDateButton: {
        marginTop: theme.spacing(2),
    },
    publishDateButtonText: {
        fontSize: '1.1em',
        color: theme.palette.primary.dark,
        fontFamily: "ArcherPro",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.1em",
        },
    },
    aboutText: {
        fontSize: '1.2em',
        color: theme.palette.primary.dark,
        fontStyle: "italic",
        textAlign:"center",
        marginTop: theme.spacing(2),
        fontFamily: "ArcherPro",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.2em",
        },
    },
    partText: {
        marginTop: theme.spacing(5),
        color: theme.palette.primary.dark,
        fontSize: "1.3em",
        fontFamily: "ArcherPro",
        letterSpacing: "0.8px",
        lineHeight: "32px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.3em",
        },
    },
    endImage: {
        marginBottom: theme.spacing(6)
    },
    logoGrid: {
        margin: "auto"
    },
    logo: {
        width: 60,
        [theme.breakpoints.down("xs")]: {
            width: 40,
        },
        [theme.breakpoints.only("xs")]: {
            width: 40,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: 40,
        },
        [theme.breakpoints.only("sm")]: {
            width: 40,
        },
    },
    dividerGrid: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.only("md")]: {
            marginTop: theme.spacing(5),
        },
    },
    next: {
        color: theme.palette.primary.dark,
        fontFamily: "ArcherPro",
        fontSize: "1.6em",
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
    },
    nextButton: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    nextTitle: {
        color: theme.palette.primary.main,
        fontFamily: "ArcherProBold",
        fontSize: "1.2em",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1em",
        },
    }
}));

const Article_two = () => {
    const parts: Array<ArticlePart> = [
        {
            content: ""
        },
    ];

    const articleInfo: ArticleInfo = {
        title: "True Happiness – Goal of a Spiritual Aspirant‌",
        publishDate: "March 18, 2018",
        about: "Adapted from dialogue between His Holiness Shri Gurudev Shriniwasji and spiritual aspirants in USA",
        parts: parts,
    };

    const classes = useStyles();
    const [article, setArticle] = useState(articleInfo);

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.logoGrid}>
                    <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg"/>
                    <Typography className={classes.title} variant="h4" component="div" color="primary">
                        {article.title}
                    </Typography>
                    <Typography component="div" variant="subtitle1" className={classes.publishDateButtonText}>
                        {article.publishDate}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.dividerGrid} />

                <Grid item xs={1} sm={1} md={1} lg={3} />
                <Grid item xs={10} sm={10} md={10} lg={6} className={classes.grid}>
                    {
                        article.about ? (
                            <Typography className={classes.aboutText} component="div" variant="body1">
                                {article.about}
                            </Typography>
                        ): ""
                    }

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev:</b> This is the beginning of Hindu New Year. My greetings and my good wishes are with all of you. What should be our goal as a spiritual aspirant or a spiritual seeker? You might have some idea about it.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Participant 1:</b> Getting knowledge.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev:</b> Is it knowledge or wisdom that we seek?
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Participant 1:</b> Wisdom.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev:</b> Wisdom sheds light upon both the worlds – the world in which we live and the transcendental world which lies beyond the material world. True wisdom also helps us understand answers to the three basic questions - Who am I? Who do I belong to? Who is mine? If you get correct answers of these three questions, then you are the wisest person because the whole wisdom is comprised in the answers to these three basic questions.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Who I am (कोऽहं)?</b> - a person may know everything, but he may not know his real nature.
                        <br />
                        <b>Who I belong to (कस्यास्महं)? </b> - Whom do I belong to in the real sense of the term?
                        <br/>
                        <b>Who is mine (कोस्तिमम)? </b> - in the true sense of the term.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        If you get correct answers to these questions, then you will be the wisest and hence the happiest person in this world. Remember, wisdom is always connected with happiness and happiness is connected with wisdom. Wisdom without happiness is no wisdom at all. Happiness that denies wisdom is false or apparent happiness. For real happiness, the two things, happiness and wisdom, must come together. Real happiness, which you may call bliss or joy, must be an outcome of wisdom.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        A spiritual seeker is a person who seeks the correct answers of the three basic questions I mentioned earlier. He must not stop his inquiry unless he is successful in understanding the correct answers of these three questions. The purpose of a spiritual seeker is the pursuit of wisdom or knowledge connected with these three questions. Despite knowing everything, if I fail to find correct answers of these three questions, then a wise person would not treat me as a man of wisdom.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        What are the characteristics of a happy person? How can you judge that a person is happy? I may say that I am the happiest person because I have so many things or objects that give me pleasure. Yet, we find that people are unhappy although they have so many things that they treat as objects of pleasure. Despite collecting all these objects, they do not feel happy. What then are the characteristics of a truly happy person?
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        Everyone in this world wants to be happy. A person maybe a spiritual seeker or a man in pursuit of worldly pleasures, but the pursuit of happiness is a common characteristic between both persons. A person of worldly attitude may want happiness from this world and hence may want to earn money to buy things. A person who claims himself to be a spiritual Seeker may seek happiness in the pursuit of the Divine. Both are trying to make themselves happy in this world. So, what does it mean when someone says that I want happiness? Will you tell me?
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Aspirant 2:</b> That he is completely satisfied in every aspect.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev (to another aspirant):</b> What is your concept of happiness?
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Aspirant 1:</b> My longing has ceased.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev:</b> You mean when desires vanish happiness emerges. This is what you mean to say. All of us are seekers of happiness. That is one common point amongst all of us. Our ways may be different, our concept about happiness might be different, but all of us seek the same thing which we call happiness. If you know the exact meaning of happiness, then you will be able to understand the correct way of obtaining it. If you know your destination correctly, then you will be able to know a correct way to reach your destination.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev (to another aspirtant):</b> You tell me what do you want?
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Aspirant 3:</b>  Complete freedom to experience happiness.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev (to another aspirant):</b> What are your views of experiencing happiness?
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Aspirant 4:</b> Experiencing what I love.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev:</b>  All of us like some things and dislike some things. When you are successful in getting something that you like, it makes you happy. However, if you fail to get what you like then it makes you unhappy. When we become successful in getting all those things that we like,  then we become happy. And yet you see people get all the things that they like and still are not happy. Despite acquiring, controlling, and exercising complete mastery over all the things that they like, a person might say that he or she is not happy. This does happen, does it not?
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Aspirant 1:</b> Yes, because they are not content.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev:</b> Why? They claim they have everything that they like.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Aspirant:</b> Their happiness is rests on something which is external.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        <b>Shri Gurudev:</b> Yes, they may say that their happiness rests on some external thing which, if they get, will make them happy. Of course, this is the initial way of leading oneself towards happiness. Yet, when you utilize all your skills to get the objects you like and eventually succeed in obtaining all those objects, you may still feel that there is something lacking. You may feel that I have still not obtained what I really seek. What is it that you seek then? A person may wish to earn million dollars and make that his objective. He may become successful in achieving his objective and have a million dollars with him or in his bank, but still that person might think that something is lacking in his achievement. He might feel, “I have achieved what I wanted to, but still my achievement is not complete.”
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        Despite all the efforts to achieve the things you like and eventually succeeding in getting those objects, you will say that you are not happy if your achievement lacks a sense of fulfillment. So, happiness is always connected with a sense of fulfillment. In Sanskrit we call this कृतकृत्यता or धन्यता.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        I must get the thing that gives me a sense of fulfillment. It does not have to be an object of my likes. It should be such that once I get it, I would feel that I have everything. It is this stage that you say as “vanishing of all desires.” Are you following me? There is no doubt that cessation of desire leads to happiness. Cessation of desire is in fact the negative expression of the same state. The positive description for this state is a sense of fulfillment. Total and complete fulfillment - a sense that will never end. That sense of fulfillment is the correct sign of true happiness. A true spiritual seeker always seeks the truth. You may call it reality, you may call it God, you may call it Brahman - words are immaterial. It is the search for such a truth that once realized will give you a sense of complete, total, and an unending fulfillment.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        How to reach up to that truth, up to that real? That is a problem of all spiritual seekers. There are so many paths, ways, and disciplines to reach up to that truth. You need to try them, select them, and begin your search.
                    </Typography>

                    <div>
                        <Typography component="div" className={classes.next}>
                            Next
                        </Typography>
                        <Button href="/articles/1" endIcon={<ArrowForward color="primary" />} className={classes.nextButton}>
                            <Typography component="div" className={classes.nextTitle}>
                                Vedic‌ ‌Hinduism
                            </Typography>
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={3} />
            </Grid>
        </>
    );
};

export default Article_two;
