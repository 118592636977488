import {State} from "../../store/state";
import {connect} from "react-redux";
import Books from "../../components/home/books/books";

export interface BooksHomeStateProps {
    language: string;
}

const mapStateToProps = (state: State): BooksHomeStateProps => ({
    language: state.header.language,
});

const BooksHomeContainer = connect(
    mapStateToProps,
)(Books);

export default BooksHomeContainer;
