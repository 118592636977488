import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Dialog,
    Grid,
    makeStyles,
    Typography,
    CardActions,
    IconButton,
} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import GalleryDetails from "../details/details";
import {Gallery} from "../../../types/gallery";
import axios, {AxiosResponse} from "axios";
import {apiURL} from "../../../App";
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import {translateText} from "../../../utils/utils";
import {GalleryStateProps} from "../../../containers/gallery";

const useStyle = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        minHeight: "100vh",
        minWidth: 375,
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.only("md")]: {
            marginTop: theme.spacing(5),
        },
    },
    logoGrid: {
        margin: "auto"
    },
    logo: {
        width: 60,
        [theme.breakpoints.down("xs")]: {
            width: 40,
        },
        [theme.breakpoints.only("xs")]: {
            width: 40,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: 40,
        },
        [theme.breakpoints.only("sm")]: {
            width: 40,
        },
    },
    title: {
        fontFamily: "ArcherProBold",
        color: theme.palette.primary.dark,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(8),
        fontSize: "2.5em",
        letterSpacing: '1px',
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.4em",
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.4em",
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "2em",
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "2em",
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2.5em",
        },
    },
    filter: {
    },
    filterListDivider: {
        marginLeft: theme.spacing(1)
    },
    filterList: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
    filterTitle: {
        fontWeight: "bold",
        fontSize: "1em",
        display: "inline-block",
        verticalAlign: "middle",
        marginLeft: theme.spacing(2),
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.8em"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.6em"
        }
    },
    filterItemText: {
        fontSize: "1em",
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.8em"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.6em"
        }
    },
    eventYearList: {
        marginTop: theme.spacing(2)
    },
    cardRoot: {
        display: 'flex',
        marginLeft: theme.spacing(4),
        marginRight: theme.spacing(4),
        marginBottom: theme.spacing(6),
        [theme.breakpoints.down("xs")]: {
            display: 'block',
        },
        [theme.breakpoints.only("xs")]: {
            display: 'block',
        },
        [theme.breakpoints.between("xs", "sm")]: {
            display: 'block',
        },
        [theme.breakpoints.only("sm")]: {
            display: 'block',
        },
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
        width: "100%"
    },
    content: {
        textAlign: "center",
    },
    cover: {
        height: '50%',
        [theme.breakpoints.down("xs")]: {
            height: 200
        },
        [theme.breakpoints.only("xs")]: {
            height: 200
        },
        [theme.breakpoints.between("xs", "sm")]: {
            height: 240
        },
        [theme.breakpoints.only("sm")]: {
            height: 400
        },
    },
    cardTitle: {
        fontFamily: "ArcherProBold",
        fontSize: "1.4em",
        color: theme.palette.primary.main,
        textAlign: "center",
        wordBreak: "break-word",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.4em",
        },
    },
    cardSubtitle: {
        fontFamily: "ArcherPro",
        color: theme.palette.primary.dark,
        fontSize: "1em",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.7em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.7em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1em",
        },
    },
    description: {
        fontFamily: "ArcherPro",
        color: theme.palette.primary.dark,
        fontSize: "1.3em",
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.9em",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.9em",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.9em",
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.3em",
        },
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: "auto",
        marginBottom: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    imageButton: {
        padding: theme.spacing(1.3)
    },
    imageButtonText: {
        fontFamily: "ArcherProBold",
        fontSize: "1.2em",
        marginLeft: theme.spacing(1),
        textTransform: "none",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.2em",
        },
    },
    progress: {
        margin: "auto"
    },
    dividerGrid: {
        marginTop: theme.spacing(0),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.only("md")]: {
            marginTop: theme.spacing(5),
        },
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}));

const GalleryLanding = (props: GalleryStateProps) => {
    const {
        language,
    } = props;

    const classes = useStyle();

    const defaultGalleries: Array<Gallery> = [];

    const [galleries, setGalleries] = useState(defaultGalleries);
    const [showGallery, setShowGallery] = useState(false);
    const [galleryId, setGalleryId] = useState(1);

    useEffect(() => {
        axios.get(`${apiURL}/api/Albums`, {
            headers: {
                "ApiKey": "SVAM",
            }
        }).then((result: AxiosResponse<Array<Gallery>>) => {
            setGalleries(result.data);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    const handleShowGallery = (index: number = 1) => {
        setGalleryId(index);
        setShowGallery(true);
    };

    return (
      <>
          <Grid container className={classes.root}>
              <Grid item xs={12} className={classes.logoGrid}>
                  <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg"/>
                  <Typography variant="h4" className={classes.title}>
                      Gallery
                  </Typography>
              </Grid>

              <Grid item xs={1} />
              <Grid item xs={10}>
                  <Grid container>
                      {
                          galleries && galleries.length > 0 ? (
                              galleries.map((gallery: Gallery) => (
                                  <>
                                      <Grid item xs={12} sm={6} md={4} lg={4}>
                                          <a href="" onClick={(e) => {
                                              e.preventDefault();
                                              handleShowGallery(gallery.albumId);
                                          }} style={{
                                              "textDecoration": "none"
                                          }}>
                                              <CardMedia
                                                  className={classes.media}
                                                  image={gallery.albumCoverUrl}
                                                  title={gallery.albumName}
                                              />
                                              <CardContent>
                                                  <Typography variant="body1" color="primary" className={classes.cardTitle}>
                                                      {
                                                          translateText(
                                                              language,
                                                              gallery.albumName || "",
                                                              gallery.albumNameMarathi || "",
                                                          )
                                                      }
                                                  </Typography>
                                                  <Typography variant="body1" color="textSecondary" component="p">
                                                      {
                                                          translateText(
                                                              language,
                                                              `Year: ${gallery.year}`,
                                                              `वर्ष: ${gallery.year}`
                                                          )
                                                      }
                                                  </Typography>
                                              </CardContent>
                                          </a>
                                      </Grid>
                                  </>
                              ))
                          ) : (
                              <div className={classes.progress}>
                                  <CircularProgress />
                              </div>
                          )
                      }
                  </Grid>
              </Grid>
              <Grid item xs={1} />
          </Grid>
          <Dialog open={showGallery} onClose={() => setShowGallery(false)} maxWidth="lg" fullWidth>
              <GalleryDetails index={galleryId}/>
          </Dialog>
      </>
    );
};

export default GalleryLanding;
