import {Header, State} from "../store/state";
import {connect} from "react-redux";
import GurudevComponent from "../components/gurudev/gurudev";

export interface GurudevStateProps {
    header: Header;
}

export interface GurudevDispatchProps {}

const mapStateToProps = (state: State): GurudevStateProps => ({
    header: state.header,
});

const GurudevContainer = connect(
    mapStateToProps,
)(GurudevComponent);

export default GurudevContainer;
