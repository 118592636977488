export interface Gallery {
    "albumId"?: number,
    "albumName"?: string,
    "albumCoverUrl"?: string,
    "description"?: string,
    "year"?: number,
    "createdOn"?: string,
    "createdBy"?: number,
    "isActive"?: boolean,
    "isDeleted"?: boolean,
    "albumNameMarathi"?: string,
    "descriptionMarathi"?: string,
}

export interface Photo {
    "photoId"?: number,
    "albumId"?: number,
    "photoUrl"?: string,
    "description"?: string,
    "createdOn"?: string,
    "createdBy"?: number,
    "isActive"?: boolean,
    "isDeleted"?: boolean
}

export interface GalleryImage {
    original?: string;
    thumbnail?: string;
}

export const convertImages = (images: Array<Photo>): Array<GalleryImage> => {
    return images.map((image: Photo) => {
        let resp: GalleryImage = {
            original: image.photoUrl,
            thumbnail: image.photoUrl,
        };
        return resp;
    });
};
