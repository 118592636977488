import {Header, State} from "../../store/state";
import {connect} from "react-redux";
import GalleryComponent from "../../components/home/gallery/gallery";

export interface GalleryStateProps {
    header: Header;
}

const mapStateToProps = (
    state: State,
): GalleryStateProps => ({
    header: state.header,
});

const GalleryContainer = connect(
    mapStateToProps,
)(GalleryComponent);

export default GalleryContainer;
