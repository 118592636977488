import React from 'react';
import {Button, Grid, makeStyles, Typography,} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import {translateText} from "../../../utils/utils";
import {VideosStateProps} from "../../../containers/home/videos";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
        marginTop: theme.spacing(15),
        marginBottom: theme.spacing(15),
        minWidth: 375,
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(0),
        },
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(5),
        },
    },
    infoSection: {
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            paddingLeft: 0,
        },
        [theme.breakpoints.only("xs")]: {
            paddingLeft: 0,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            paddingLeft: 0,
        },
        [theme.breakpoints.only("sm")]: {
            paddingLeft: 0,
        },
    },
    title: {
        marginTop: theme.spacing(10),
        fontFamily: "ArcherProBold",
        fontSize: "2.5em",
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
            textAlign: "center",
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.4em",
            textAlign: "center",
            marginTop: theme.spacing(1),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.4em",
            textAlign: "center",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.4em",
            textAlign: "center",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "2.2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2.5em",
        },
    },
    description: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        fontFamily: "ArcherPro",
        fontSize: "1.5em",
        letterSpacing: '1px',
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
            padding: 10,
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
            padding: 10,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.5em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.5em",
        },
    },
    buttonText: {
        fontFamily: "ArcherProBold",
        fontSize: "1.3em",
        letterSpacing: "1px",
        textTransform: "none",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.5em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.5em",
        },
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.light,
    },
    iframeGrid: {
        position: "relative",
        overflow: "hidden",
        width: "100%",
        paddingTop: "38.25%",
        [theme.breakpoints.down("xs")]: {
            paddingTop: "80%",
        },
        [theme.breakpoints.only("xs")]: {
            paddingTop: "80%",
        },
    },
    videoFrame: {
        position: "absolute",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: "100%",
        height: "100%",

        [theme.breakpoints.between("xs", "sm")]: {
            height: "70%",
        },
        [theme.breakpoints.only("sm")]: {
            height: "70%",
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: "100%",
        },
        // [theme.breakpoints.only("md")]: {
        //     height: "40vh",
        // },
        // [theme.breakpoints.between("md", "lg")]: {
        //     height: "40vh",
        // },
        // [theme.breakpoints.down("lg")]: {
        //     height: "45vh",
        // },
        // [theme.breakpoints.only("lg")]: {
        //     height: "50vh",
        // },
    }
}));

const VideosComponent = (
    props: VideosStateProps,
) => {
    const classes = useStyles();
    const  {
        header,
    } = props;

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item sm={2} md={2} lg={2} />
                <Grid component="div" item xs={12} sm={8} md={8} lg={8} className={classes.iframeGrid}>
                    <iframe className={classes.videoFrame} title="meaning_of_shreevidya"
                            src="https://www.youtube.com/embed/GVOyTV5ztmU"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    />
                </Grid>
                <Grid item sm={2} md={2} lg={2} />

                <Grid item sm={2} md={2} lg={3} />
                <Grid item xs={12} sm={8} md={8} lg={6} className={classes.infoSection}>
                    <Typography component="div" variant="h5" className={classes.title}>
                        {
                            translateText(
                                header.language,
                                "Meaning of Shreevidya",
                                "श्रीविद्येचा अर्थ"
                            )
                        }
                    </Typography>
                    <Typography component="div" variant="body1" className={classes.description}>
                        {
                            translateText(
                                header.language,
                                "Shreevidyā or Shree-Roopini Vidyā is the highest desirable thing in life. So, what is the meaning or core essence of Shreevidyā? Hear Shri Gurudev Shriniwasji explain the true nature of Shreevidyā or supreme wisdom.",
                                "श्रीविद्या किंवा श्रीरूपिणी विद्या ही जीवनातील सर्वोच इष्ट गोष्ट आहे. तर, श्रीविद्याचा अर्थ किंवा मूळ सार काय आहे? श्री गुरुदेव श्रीनिवासजींकडून श्रीविद्या किंवा परमज्ञानाचे खरे स्वरुप समजावून घ्या."
                            )
                        }
                    </Typography>
                    <Button target="_blank" rel="noopener noreferrer" variant="text" endIcon={<ArrowForward />} href="https://www.youtube.com/channel/UCu9TNGbekVKJaZXHJR140ig" color="primary" className={classes.link} >
                        <Typography component="span" variant="body1" className={classes.buttonText}>
                            {
                                translateText(
                                    header.language,
                                    "Explore Videos",
                                    "विडिओ"
                                )
                            }
                        </Typography>
                    </Button>
                </Grid>
                <Grid item sm={2} md={2} lg={3} />
            </Grid>
        </>
    )
};

export default VideosComponent;
