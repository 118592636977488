import {Header, State} from "../store/state";
import {connect} from "react-redux";
import AboutComponent from "../components/about/about";

export interface AboutStateProps {
    header: Header;
}

const mapStateToProps = (state: State): AboutStateProps => ({
    header: state.header,
});

const AboutContainer = connect(
    mapStateToProps,
)(AboutComponent);

export default AboutContainer;
