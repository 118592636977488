import {AnyAction} from 'redux';
import * as types from '../actions/order-form';
import {OrderFormState} from "../store/state";

const defaultState: OrderFormState = {
  show: false,
  bookId: 0,
};

const showOrderForm = (state: OrderFormState, action: AnyAction): OrderFormState => ({
  ...state,
  show: true,
  bookId: action.bookId,
});

const closeOrderForm = (state: OrderFormState, action: AnyAction): OrderFormState => ({
  ...state,
  show: false,
  bookId: 0,
});

const orderForm = (state: OrderFormState = defaultState, action: AnyAction): OrderFormState => {
  switch (action.type) {
    case types.SHOW_ORDER_FORM:
      return showOrderForm(state, action);
    case types.CLOSE_ORDER_FORM:
      return closeOrderForm(state, action);
    default:
      return state;
  }
}

export default orderForm;
