import * as React from 'react';
import {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {
    AppBar,
    Divider,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Toolbar,
    Typography,
} from '@material-ui/core';
import {Eco, Home, Info, LibraryBooks, Menu, Notes, Spa, ViewCarousel,} from '@material-ui/icons';
import {HeaderDispatchProps, HeaderStateProps} from "../../containers/header";
import {Link} from "react-router-dom";
import {translateText} from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        backgroundColor: "white",
        color: "black",
        position: "static",
        minWidth: 375,
        [theme.breakpoints.down("xs")]: {
            paddingBottom: 10
        },
        [theme.breakpoints.only("xs")]: {
            paddingBottom: 10
        }
        // width: window.screen.width
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    toolbar: {
        fontWeight: "bold",
        height: 100,
        [theme.breakpoints.down("xs")]: {
            height: 150,
        }
    },
    title: {
        textAlign: "left",
        fontSize: '1.6em',
        fontFamily: 'ArcherProBold',
        letterSpacing: '1px',
        color: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.7em"
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.7em",
            textAlign: "center"
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.9em"
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.9em"
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "0.9em"
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.2em"
        },
    },
    drawerList: {
        width: "30vw",
        minWidth: 100,
        [theme.breakpoints.down("xs")]: {
            width: "70vw",
        },
        [theme.breakpoints.only("xs")]: {
            width: "60vw",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: "60vw",
        },
        [theme.breakpoints.only("sm")]: {
            width: "50vw",
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: "50vw",
        },
        [theme.breakpoints.only("md")]: {
            width: "50vw",
        },
        [theme.breakpoints.between("md", "lg")]: {
            width: "40vw",
        },
        [theme.breakpoints.only("lg")]: {
            width: "30vw",
        },
    },
    drawerSubHeader: {
        textAlign: "center",
        marginTop: theme.spacing(3)
    },
    drawerItemText: {
        fontWeight: "bold",
        fontSize: "1.2em",
        color: theme.palette.primary.dark,
        marginLeft: theme.spacing(1),
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.2em",
        },
    },
    drawerItemIcon: {
        fontSize: "1.8em",
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em"
        }
    },
    icon: {
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em"
        }
    },
    iconButton: {
    },
    ganapatiLogo: {
        margin: "auto",
        height: "50px",
    },
    titleGrid: {
        verticalAlign: "middle",
        margin: "auto",
    },
    showOnSmall: {
        display: "none",
        [theme.breakpoints.down("xs")]: {
            display: "block",
            paddingTop: 10
        },
        [theme.breakpoints.only("xs")]: {
            display: "block",
            paddingTop: 10
        }
    },
    hideOnSmall: {
        display: "block",
        [theme.breakpoints.down("xs")]: {
            display: "none"
        },
        [theme.breakpoints.only("xs")]: {
            display: "none"
        }
    },
    formControl: {
        marginRight: theme.spacing(2),
        minWidth: 120,
    },
    link: {
        textDecoration: "none",
    },
    languageAndDrawerSection: {
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
            marginTop: "0.5em",
            textAlign: "center",
        },
    }
}));

const HeaderComponent = (props: HeaderStateProps & HeaderDispatchProps) => {
    const {
        setLanguage,
        state,
    } = props;

    const [open, setOpen] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        console.log(state.language);
    }, [state.language]);

    function ListItemLink(props: any) {
        return <ListItem button component="a" {...props} />;
    }

    const handleLanguageChange = (value: any) => {
        setLanguage(value);
    };

    return (
        <AppBar position="static" className={classes.root}>
            <Toolbar className={classes.toolbar}>
                <Grid container>
                    <Grid component="div" item xs={12} className={classes.showOnSmall}>
                        <img src="http://d3o76blco64tu5.cloudfront.net/misc/ganesh.png" className={classes.ganapatiLogo}/>
                    </Grid>
                    <Grid component="div" item xs={12} sm={4} className={classes.titleGrid}>
                        <Typography variant="h6" className={classes.title}>
                            <Link to="/" style={{
                                textDecoration: "none",
                                color: "#4a4a4a"
                            }}>
                                {
                                    translateText(
                                        state.language,
                                        '|| Shreevidya Akhandmahayog ||',
                                        '|| श्रीविद्या अखंडमहायोग ||'
                                    )
                                }
                            </Link>
                        </Typography>
                    </Grid>
                    <Grid component="div" item xs={12} sm={4} className={classes.hideOnSmall}>
                        <img src="http://d3o76blco64tu5.cloudfront.net/misc/ganesh.png" className={classes.ganapatiLogo}/>
                    </Grid>
                    <Grid component="div" item xs={12} sm={4} className={classes.languageAndDrawerSection}>
                        <FormControl className={classes.formControl}>
                            <InputLabel id="demo-simple-select-label">Language</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={state.language}
                                onChange={(e) => handleLanguageChange(e.target.value)}
                            >
                                <MenuItem value={"english"}>English</MenuItem>
                                <MenuItem value={"marathi"}>मराठी</MenuItem>
                            </Select>
                        </FormControl>
                        <IconButton edge="start" aria-label="menu" onClick={() => setOpen(!open)} className={classes.iconButton}>
                            <Menu fontSize="large" className={classes.icon}/>
                        </IconButton>
                    </Grid>
                </Grid>
            </Toolbar>
            <Drawer anchor="right" open={open} onClose={() => setOpen(false)}>
                <List className={classes.drawerList}>
                    <ListSubheader component="div" className={classes.drawerSubHeader}>
                        <img width="40px" src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg"/>
                    </ListSubheader>
                    <Divider />
                    <Link to="/" className={classes.link}>
                        <ListItem style={{
                            marginTop: "2em",
                        }}>
                            <ListItemIcon>
                                <Home fontSize="default" className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography component="div" variant="body1" className={classes.drawerItemText}>
                                    {
                                        translateText(
                                            state.language,
                                            'Home',
                                            'मुख्य'
                                        )
                                    }
                                </Typography>
                            } />
                        </ListItem>
                    </Link>
                    <Link to="/about" className={classes.link}>
                        <ListItem>
                            <ListItemIcon>
                                <Info fontSize="default" className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography component="div" variant="body1" className={classes.drawerItemText}>
                                    {
                                        translateText(
                                            state.language,
                                            'About Shreevidya Akhandmahayog',
                                            'श्रीविद्यामहायोगाविषयी थोडक्यात'
                                        )
                                    }
                                </Typography>
                            } />
                        </ListItem>
                    </Link>
                    <Link to="/gurudev" className={classes.link}>
                        <ListItem>
                            <ListItemIcon>
                                <Spa fontSize="default" className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography component="div" variant="body1" className={classes.drawerItemText}>
                                    {
                                        translateText(
                                            state.language,
                                            'About Shree Gurudev',
                                            'श्रीगुरूदेवांचा परिचय'
                                        )
                                    }
                                </Typography>
                            } />
                        </ListItem>
                    </Link>
                    <Link to="/historical_survey" className={classes.link}>
                        <ListItem>
                            <ListItemIcon>
                                <Eco fontSize="default" className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography component="div" variant="body1" className={classes.drawerItemText}>
                                    {
                                        translateText(
                                            state.language,
                                            'Shreevidya Akhandmahayog - Historical Survey',
                                            'श्रीविद्यामहायोग - ऐतिहासिक माहिती व सिद्धांत'
                                        )
                                    }
                                </Typography>
                            } />
                        </ListItem>
                    </Link>
                    <Link to="/books" className={classes.link}>
                        <ListItem>
                            <ListItemIcon>
                                <LibraryBooks fontSize="default" className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography component="div" variant="body1" className={classes.drawerItemText}>
                                    {
                                        translateText(
                                            state.language,
                                            'Books',
                                            'पुस्तके'
                                        )
                                    }
                                </Typography>
                            } />
                        </ListItem>
                    </Link>
                    <Link to="/gallery" className={classes.link}>
                        <ListItem>
                            <ListItemIcon>
                                <ViewCarousel fontSize="default" className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography component="div" variant="body1" className={classes.drawerItemText}>
                                    {
                                        translateText(
                                            state.language,
                                            'Gallery',
                                            'गॅलरी'
                                        )
                                    }
                                </Typography>
                            } />
                        </ListItem>
                    </Link>
                    <Link to="/articles/1" className={classes.link}>
                        <ListItem>
                            <ListItemIcon>
                                <Notes fontSize="default" className={classes.icon}/>
                            </ListItemIcon>
                            <ListItemText primary={
                                <Typography component="div" variant="body1" className={classes.drawerItemText}>
                                    {
                                        translateText(
                                            state.language,
                                            'Articles',
                                            'लेख'
                                        )
                                    }
                                </Typography>
                            } />
                        </ListItem>
                    </Link>
                </List>
            </Drawer>
        </AppBar>
    )
};

export default HeaderComponent;
