import * as React from 'react';
import {useState} from 'react';
import './home.scss'
import '@brainhubeu/react-carousel/lib/style.css';
import HeroContainer from "../../containers/home/hero";
import AboutContainer from "../../containers/home/about";
import BlogsContainer from '../../containers/home/blogs';
import VideosContainer from '../../containers/home/videos';
import GalleryContainer from '../../containers/home/gallery';
import MessageContainer from '../../containers/home/message';
import BooksHomeContainer from '../../containers/home/books';
import makeStyles from "@material-ui/core/styles/makeStyles";
import HistoricalSurveyContainer from "../../containers/home/historical-survey";
import PromotionsContainer from "../../containers/home/promotions";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        minHeight: "100vh",
        minWidth: 375,
    },
    logo: {
        marginBottom: theme.spacing(2),
        width: 40,
        [theme.breakpoints.down("xs")]: {
            width: 40,
        },
        [theme.breakpoints.only("xs")]: {
            width: 40,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: 40,
        },
        [theme.breakpoints.only("sm")]: {
            width: 40,
        },
    },
    announcementTitle: {
        fontFamily: "ArcherProBold",
        color: theme.palette.primary.dark,
        fontSize: "2em",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
            padding: 10,
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
            padding: 10,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
            padding: 10,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.3em",
            padding: 10,
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.5em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.7em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2em",
        },
    },
}));

const Home = () => {
    const classes = useStyles();

    const [showPromotion, setShowPromotion] = useState(true);

    let closeImg = {cursor:'pointer', float:'right', marginTop: '5px', width: '20px'};

    return (
      <>
        <div className={classes.root}>
            {/*<Dialog fullWidth={true} style={{*/}
            {/*  minWidth: "375px"*/}
            {/*}} onClose={() => setShowPromotion(false)} aria-labelledby="simple-dialog-title" open={showPromotion} maxWidth="md">*/}
            {/*    <DialogTitle style={{*/}
            {/*        backgroundColor: "#f7f5f2",*/}
            {/*    }}>*/}
            {/*        <Box display="flex" alignItems="center">*/}
            {/*            <Box flexGrow={1} style={{*/}
            {/*                textAlign: "center"*/}
            {/*            }} >*/}
            {/*                <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg" alt="divider"/>*/}
            {/*                <Typography className={classes.announcementTitle}>*/}
            {/*                    Coming Soon*/}
            {/*                </Typography>*/}
            {/*            </Box>*/}
            {/*            <Box>*/}
            {/*                <IconButton color="primary" onClick={() => setShowPromotion(false)}>*/}
            {/*                    <CloseRounded />*/}
            {/*                </IconButton>*/}
            {/*            </Box>*/}
            {/*        </Box>*/}
            {/*    </DialogTitle>*/}
            {/*    <Promotions />*/}
            {/*</Dialog>*/}
            <HeroContainer/>
            <PromotionsContainer />
            <AboutContainer />
            <HistoricalSurveyContainer />
            <BlogsContainer/>
            <BooksHomeContainer />
            <MessageContainer />
            <VideosContainer />
            <GalleryContainer />
        </div>
      </>
    )
};

export default Home;
