import {Header, State} from "../store/state";
import {connect} from "react-redux";
import BooksLandingComponent from "../components/books/landing/landing";

export interface BooksLandingStateProps {
    header: Header;
}

const mapStateToProps = (
    state: State,
): BooksLandingStateProps => ({
    header: state.header,
});

const BooksLandingContainer = connect(
    mapStateToProps,
)(BooksLandingComponent);

export default BooksLandingContainer;
