import {Header} from "../store/state";
import {AnyAction} from "redux";
import {SET_LANGUAGE} from '../actions/header';

const defaultState: Header = {
    language: 'english',
};

const header = (state: Header = defaultState, action: AnyAction): Header => {
    switch (action.type) {
        case SET_LANGUAGE:
            console.log('language', action.language);
            return {
                ...state,
                language: action.language,
            };
        default:
            return defaultState;
    }
};

export default header;
