import React from 'react';
import {Link} from "react-router-dom";
import {
    makeStyles,
    Grid,
    Typography,
    Button
} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import {AboutStateProps} from "../../../containers/home/about";
import {translateText} from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
    about: {
        paddingBottom: 100,
        // marginTop: 950,
        paddingTop: 100,
        minWidth: 375,
        backgroundColor: "#f7f5f2",
    },
    messageGrid: {
        // [theme.breakpoints.down("xs")]: {
        //     paddingLeft: "0.5em",
        //     paddingRight: "0.5em",
        // },
        // [theme.breakpoints.only("xs")]: {
        //     paddingLeft: "0.5em",
        //     paddingRight: "0.5em",
        // },
        // [theme.breakpoints.between("xs", "sm")]: {
        //     paddingLeft: "0.5em",
        //     paddingRight: "0.5em",
        // },
    },
    message: {
        fontSize: "1.7em",
        letterSpacing: "1px",
        fontFamily: "ArcherProBold",
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.1em",
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.2em",
            paddingLeft: 10,
            paddingRight: 10,
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.6em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.7em",
        },
    },
    messageAuthor: {
        marginTop: theme.spacing(3),
        fontSize: "1.2em",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.8em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.8em",
        },
    },
    aboutButton: {
        marginTop: theme.spacing(7),
        width: 500,
        padding: theme.spacing(1.3),
        [theme.breakpoints.down("xs")]: {
            width: 200,
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("xs")]: {
            width: 200,
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: 300,
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("sm")]: {
            width: 300,
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.between("sm", "md")]: {
            width: 300,
        },
        [theme.breakpoints.only("md")]: {
            width: 400,
        },
        [theme.breakpoints.between("md", "lg")]: {
            width: 500,
        },
        [theme.breakpoints.only("lg")]: {
            width: 500,
        },
    },
    aboutButtonText: {
        fontSize: "1.5em",
        marginLeft: theme.spacing(1),
        textTransform: "none",
        letterSpacing: "2px",
        fontFamily: "ArcherProBold",
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
    },
    link: {
        textDecoration: "none"
    }
}));

const About = (
    props: AboutStateProps,
) => {
    const classes = useStyles();
    const {
        header,
    } = props;

    return (
        <>
            <Grid item xs={12} className={classes.about}>
                <Grid container>
                    <Grid item sm={3} component="div" />
                    <Grid component="div" sm={6} xs={12} className={classes.messageGrid}>
                        <Typography component="p" variant="h6" className={classes.message}>
                            {
                                translateText(
                                    header.language,
                                    `
                                    Shree Mata, The Mother Divine she loves her children with unconditional love. To
                            feel and experience her divine unconditional love we, her children, must try to love
                            her unconditionally with total egoless surrender.
                                    `,
                                    `
                                    दिव्य श्री माता, ती आपल्या मुलांवर विनाअट प्रेम करते. तिचे दैवी विनाअट प्रेम आणि अनुभव घेण्यासाठी आपण, तिच्या मुलांनी तिच्यावर पूर्ण अहंकार आत्मसमर्पण करून तिच्यावर विनाअट प्रेम करण्याचा प्रयत्न केला पाहिजे.
                                    `
                                )
                            }


                        </Typography>
                        <Typography component="p" variant="subtitle1" className={classes.messageAuthor}>
                            {
                                translateText(
                                    header.language,
                                    '- Shree Gurudev',
                                    '- श्री गुरुदेव'
                                )
                            }
                        </Typography>
                        <Link to="/about" className={classes.link}>
                            <Button variant="text" color="primary" className={classes.aboutButton} endIcon={<ArrowForward />}>
                                <Typography component="span" variant="body1" color="primary" className={classes.aboutButtonText}>
                                    {
                                        translateText(
                                            header.language,
                                            'About Shreevidya Mahayog',
                                            'श्रीविद्यामहायोगाविषयी थोडक्यात'
                                        )
                                    }
                                </Typography>
                            </Button>
                        </Link>
                    </Grid>
                    <Grid item sm={3} component="div" />
                </Grid>
            </Grid>
        </>
    )
};

export default About;
