import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Dialog, Grid, IconButton, Typography} from '@material-ui/core';
import {CloseRounded, Facebook, YouTube} from "@material-ui/icons";
import TermsAndCondition from "../terms_condition/terms_condition";
import PrivacyPolicy from "../privacy_policy/privacy_policy";
import ContactUs from "../contact_us/contact_us";
import {Link} from "react-router-dom";
import {FooterStateProps} from "../../containers/footer";
import {translateText} from "../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        display: "flex",
        minWidth: 375,
        paddingTop: 40,
        paddingBottom: 40,
        backgroundColor: theme.palette.primary.main,
        color: "white",
    },
    title: {
        fontFamily: "ArcherProBold",
        fontSize: "0.9em"
    },
    menuText: {
        fontFamily: "ArcherProBold",
        fontSize: "1.2em"
    },
    iconGrid: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    icons: {
        marginLeft: "auto"
    },
    icon: {
        marginLeft: theme.spacing(1),
        color: "white"
    },
    link: {
        textDecoration: "none",
        color: "white"
    },
    terms: {
        // height: "70vh",
    },
    logo: {
        marginBottom: theme.spacing(2),
        width: 40,
        [theme.breakpoints.down("xs")]: {
            width: 40,
        },
        [theme.breakpoints.only("xs")]: {
            width: 40,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: 40,
        },
        [theme.breakpoints.only("sm")]: {
            width: 40,
        },
    },
    contactUsTitle: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(2),
        fontFamily: "ArcherProBold",
        fontSize: "2em",
        textAlign: "center",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2em",
        },
    },
}));

const FooterComponent = (props: FooterStateProps) => {
    const classes = useStyles();

    const {
        header,
    } = props;

    const [termsOpen, setTermsOpen] = useState(false);
    const handleTermsOnClick = (e: any) => {
        e.preventDefault();
        setTermsOpen(!termsOpen);
    };
    const [policyOpen, setPolicyOpen] = useState(false);
    const handlePolicyOnClick = (e: any) => {
        e.preventDefault();
        setPolicyOpen(!policyOpen);
    };
    const [contactUsOpen, setContactUsOpen] = useState(false);
    const handleContactUsOnClick = (e: any) => {
        e.preventDefault();
        setContactUsOpen(!contactUsOpen);
    };

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <Grid container>
                        <Grid component="div" item xs={12} sm={3}>
                            <Link to="/about" rel="noopener noreferrer" className={classes.link
                            }>
                                <Typography variant="body1" color="inherit" className={classes.menuText}>
                                    {
                                        translateText(
                                            header.language,
                                            "About Us",
                                            "आमचा परिचय"
                                        )
                                    }
                                </Typography>
                            </Link>
                        </Grid>
                        <Grid component="div" item xs={12} sm={3}>
                            <a href="" onClick={(e) => handleContactUsOnClick(e)} rel="noopener noreferrer" className={classes.link}>
                                <Typography variant="body1" color="inherit" className={classes.menuText}>
                                    {
                                        translateText(
                                            header.language,
                                            "Contact Us",
                                            "आमच्याशी संपर्क करा"
                                        )
                                    }
                                </Typography>
                            </a>
                        </Grid>
                        <Grid component="div" item xs={12} sm={3}>
                            <a href="" onClick={(e) => handleTermsOnClick(e)} rel="noopener noreferrer" className={classes.link}>
                                <Typography variant="body1" color="inherit" className={classes.menuText}>
                                    {
                                        translateText(
                                            header.language,
                                            "Terms & Conditions",
                                            "नियम व अटी",
                                        )
                                    }
                                </Typography>
                            </a>
                        </Grid>
                        <Grid component="div" item xs={12} sm={3}>
                            <a href="" onClick={(e) => handlePolicyOnClick(e)} rel="noopener noreferrer" className={classes.link}>
                                <Typography variant="body1" color="inherit" className={classes.menuText}>
                                    {
                                        translateText(
                                            header.language,
                                            "Privacy Policy",
                                            "गोपनीयता धोरण"
                                        )
                                    }
                                </Typography>
                            </a>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} />
                <Grid component="div" item xs={12} className={classes.iconGrid}>
                    <IconButton className={classes.icon} aria-label="contact-us" target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/groups/Shreevidyamahayog/about">
                        <Facebook fontSize="large" />
                    </IconButton>
                    <IconButton className={classes.icon} aria-label="contact-us" target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/channel/UCu9TNGbekVKJaZXHJR140ig">
                        <YouTube fontSize="large" />
                    </IconButton>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography variant="body1" color="inherit" className={classes.title}>
                        &copy; 2021 &nbsp;
                        {
                            translateText(
                                header.language,
                                "Shreevidya Akhandmahayog. All rights reserved.",
                                "श्रीविद्याअखंडमहायोग. सर्व हक्क राखीव आहेत."
                            )
                        }
                    </Typography>
                </Grid>
            </Grid>
            <Dialog fullWidth={true} style={{
                minWidth: "375px"
            }} onClose={() => setTermsOpen(false)} aria-labelledby="simple-dialog-title" open={termsOpen} className={classes.terms}>
                <TermsAndCondition/>
            </Dialog>
            <Dialog fullWidth={true} style={{
                minWidth: "375px"
            }} onClose={() => setPolicyOpen(false)} aria-labelledby="simple-dialog-title" open={policyOpen} className={classes.terms}>
                <PrivacyPolicy/>
            </Dialog>
            <Dialog fullWidth={true} style={{
                minWidth: "375px"
            }} onClose={() => setContactUsOpen(false)} aria-labelledby="simple-dialog-title" open={contactUsOpen} className={classes.terms}>
                <Box display="flex" alignItems="center" style={{
                    padding: "20px"
                }}>
                    <Box flexGrow={1} style={{
                        textAlign: "center"
                    }} >
                        <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg" alt="divider"/>
                        <Typography className={classes.contactUsTitle}>
                            Contact Us
                        </Typography>
                    </Box>
                    <Box>
                        <IconButton onClick={() => setContactUsOpen(false)} color="primary">
                            <CloseRounded />
                        </IconButton>
                    </Box>
                </Box>
                <ContactUs/>
            </Dialog>
        </>
    )
};

export default FooterComponent;
