import {Header, State} from "../../store/state";
import {connect} from "react-redux";
import About from "../../components/home/about/about";

export interface AboutStateProps {
    header: Header;
}

const mapStateToProps = (
    state: State,
): AboutStateProps => ({
    header: state.header,
});

const AboutContainer = connect(
    mapStateToProps,
)(About);

export default AboutContainer;
