import React, {useEffect, useRef, useState} from 'react';

// @ts-ignore
import FlippingPages from 'flipping-pages';
/* IMPORTANT */
import 'flipping-pages/FlippingPages.css'
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import {
    Avatar,
    Button,
    Card,
    CardHeader,
    CircularProgress, Dialog,
    Divider,
    Grid,
    makeStyles,
    Typography,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import {CalendarToday, Language, LocalMall, MenuBook, ViewCarousel} from '@material-ui/icons';
import axios, {AxiosResponse} from "axios";
import {useParams} from "react-router";
import {BookResponse} from "../../../types/books";
import {apiURL} from "../../../App";
import OrderFormContainer from "../../../containers/order-form";
import {BookDetailsDispatchProps, BookDetailsStateProps} from "../../../containers/book-details";
import {translateText} from "../../../utils/utils";
import './styles.scss';

type BookDetailsStateDispatchProps = BookDetailsStateProps & BookDetailsDispatchProps;

const BookDetails = (props: BookDetailsStateDispatchProps) => {
    const {
        showOrderForm,
        header,
    } = props;

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(10),
            minHeight: "100vh",
            minWidth: 375,
            [theme.breakpoints.down("xs")]: {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.between("xs", "sm")]: {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.only("sm")]: {
                marginTop: theme.spacing(10),
            },
            [theme.breakpoints.only("md")]: {
                marginTop: theme.spacing(10),
            },
        },
        imageGrid: {
            justifyContent: "center"
        },
        image: {
            width: "80%",
            height: "auto",
            // [theme.breakpoints.down("xs")]: {
            //     height: 300,
            // },
            // [theme.breakpoints.only("xs")]: {
            //     height: 300,
            // },
            [theme.breakpoints.between("xs", "sm")]: {
                width: "70%"
            },
            [theme.breakpoints.only("sm")]: {
                width: "60%"
            },
            [theme.breakpoints.between("md", "sm")]: {
                width: "60%"
            },
            // [theme.breakpoints.only("md")]: {
            //     height: 400,
            // },
            // [theme.breakpoints.between("md", "lg")]: {
            //     height: 450,
            // },
            // [theme.breakpoints.only("lg")]: {
            //     height: "auto",
            // },
        },
        imageButton: {
            width: 350,
            padding: theme.spacing(1.3)
        },
        imageButtonText: {
            fontFamily: "ArcherProBold",
            fontSize: "1.3em",
            marginLeft: theme.spacing(1),
            textTransform: "none",
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "1em",
            },
        },
        title: {
            fontFamily: "ArcherProBold",
            color: theme.palette.primary.main,
            fontSize: "2em",
            textAlign: "left",
            [theme.breakpoints.down("xs")]: {
                fontSize: "1em",
                textAlign: "center",
                padding: 10,
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "1.2em",
                textAlign: "center",
                padding: 10,
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "1.2em",
                textAlign: "center",
                padding: 10,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "1.3em",
                textAlign: "center",
                padding: 10,
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "1.3em",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "1.5em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "1.7em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "2em",
            },
        },
        cardsGrid: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(3),
        },
        card: {
            marginRight: theme.spacing(8),
            width: "100%",
        },
        cardTitle: {
            fontFamily: "ArcherProBold",
            color: theme.palette.primary.main,
            fontSize: "1.2em",
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "1em",
            },
        },
        cardSubTitle: {
            fontFamily: "ArcherPro",
            color: theme.palette.primary.dark,
        },
        cardIcon: {
            fontSize: "1em",
        },
        avatar: {
            background: "none",
        },
        description: {
            marginTop: theme.spacing(5),
            textAlign: "justify",
            fontSize: "1.3em",
            lineHeight: 2,
            fontFamily: "ArcherPro",
            color: theme.palette.primary.dark,
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.8em",
                padding: 20,
                marginTop: theme.spacing(0),
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "1em",
                padding: 20,
                marginTop: theme.spacing(0),
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "1em",
                padding: 20,
                marginTop: theme.spacing(0),
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "1em",
                padding: 20,
                marginTop: theme.spacing(0),
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "1em",
                padding: 20,
                marginTop: theme.spacing(0),
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "1.3em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "1.3em",
            },
        },
        carouselMedia: {
            height: 460
        },
        contactUsButton: {
            width: 350,
            padding: theme.spacing(1.3),
            marginTop: theme.spacing(8),
            fontSize: "1em",
            [theme.breakpoints.down("xs")]: {
                width: 250,
            },
            [theme.breakpoints.only("xs")]: {
                width: 250,
            },
            [theme.breakpoints.between("xs", "sm")]: {
                width: 280,
            },
            [theme.breakpoints.only("sm")]: {
                width: 300,
            },
        },
        progress: {
            margin: "auto"
        },
        dialogPaper: {
            minWidth: '60vw',
            maxWidth: '65vw',
            minHeight: '90vh',
            maxHeight: '95vh'
        },
        flipPage: {
            "-ms-user-select": "none",
            "-moz-user-select": "none",
            "-webkit-user-select": "none",
            "user-select": "none",
            "margin": "auto",
            "display": "flex",
            "width": "80%",
            'height': '800px',
            [theme.breakpoints.down("xs")]: {
                height: '350px',
            },
            [theme.breakpoints.only("xs")]: {
                height: '450px',
            },
            [theme.breakpoints.between("xs", "sm")]: {
                height: '500px',
            },
            [theme.breakpoints.only("sm")]: {
                height: '600px',
            },
        },
        flipPageImage: {
            "display": "block",
            "max-height": "100%",
            'width': "100%"
        },
        flipPageButton: {

        }
    }));

    const classes = useStyles();

    let { bookId } = useParams();

    console.log(`id of the book ${bookId}`);

    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(0);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        setNumPages(0);
        setPageNumber(0);
    }, [showPreview]);

    function changePage(offset: number) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    const defaultBook: BookResponse = {};
    const [book, setBook] = useState(defaultBook);

    function formatDate(date: string) {
        var d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;

        return [year, month, day].join('-');
    }

    useEffect(() => {
        axios.get(`${apiURL}/api/Books/${bookId}`, {
            headers: {
                "ApiKey": "SVAM",
            }
        }).then((result: AxiosResponse<BookResponse>) => {
            result.data.publicationDate = formatDate(result.data.publicationDate || "");
            setBook(result.data);
        }).catch((error) => {
            console.log(error);
        });
    }, []);

    const previewField = showPreview ? (
        <Dialog
            fullWidth
            classes={{
                paper: classes.dialogPaper
            }}
            onClose={() => setShowPreview(!showPreview)}
            aria-labelledby="preview"
            open={showPreview}
            style={{
                minWidth: "375px",
                textAlign: "center"
            }}
        >
            <DialogContent>
                <Grid container>
                    <Grid item xs={1}>
                        <Button
                            onClick={previousPage}
                            disabled={pageNumber <= 0}
                            variant="outlined"
                            color="primary">
                            <Typography className={classes.imageButtonText} component="div" variant="body2" color="primary">
                                {
                                    translateText(
                                        header.language,
                                        "Previous",
                                        "मागे"
                                    )
                                }
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={10}>
                        <FlippingPages
                            className={classes.flipPage}
                            direction="horizontal"
                            selected={pageNumber}
                            /* touch-action attribute is required by pointer events
                            polyfill */
                            touch-action="none"
                        >
                            <img alt="image" className={classes.flipPageImage} src="http://d3o76blco64tu5.cloudfront.net/misc/kalbhairav-1.png" />
                            <img alt="image" className={classes.flipPageImage} src="http://d3o76blco64tu5.cloudfront.net/misc/kalbhairav-2.png" />
                            <img alt="image" className={classes.flipPageImage} src="http://d3o76blco64tu5.cloudfront.net/misc/kalbhairav-3.png" />
                        </FlippingPages>
                    </Grid>
                    <Grid item xs={1}>
                        <Button
                            onClick={nextPage}
                            disabled={pageNumber >= 2}
                            variant="outlined"
                            color="primary">
                            <Typography className={classes.imageButtonText} component="div" variant="body2" color="primary">
                                {
                                    translateText(
                                        header.language,
                                        "Next",
                                        "पुढे"
                                    )
                                }
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions style={{
                justifyContent: "center"
            }}>
                <Button
                    onClick={() => setShowPreview(false)}
                    variant="outlined"
                    color="primary">
                    <Typography className={classes.imageButtonText} component="div" variant="body2" color="primary">
                        {
                            translateText(
                                header.language,
                                "Close",
                                "बंद"
                            )
                        }
                    </Typography>
                </Button>
            </DialogActions>
        </Dialog>
    ) : '';

    return (
        <>
            <Grid container className={classes.root}>
                {
                    book && book.bookName !== '' ? (
                        <>
                            <Grid item xs={12} sm={12} md={4} lg={4} className={classes.imageGrid}>
                                <img className={classes.image} src={book.coverPageImage} alt="image" />

                                {/*<Button*/}
                                {/*    onClick={() => setShowPreview(true)}*/}
                                {/*    className={classes.contactUsButton} variant="outlined"*/}
                                {/*    startIcon={<MenuBook color="primary" />}*/}
                                {/*    color="primary"*/}
                                {/*    style={{*/}
                                {/*        marginTop: '0.7em'*/}
                                {/*    }}*/}
                                {/*>*/}
                                {/*    <Typography className={classes.imageButtonText} component="div" variant="body2" color="primary">*/}
                                {/*        {*/}
                                {/*            translateText(*/}
                                {/*                header.language,*/}
                                {/*                "Preview",*/}
                                {/*                "पूर्वावलोकन"*/}
                                {/*            )*/}
                                {/*        }*/}
                                {/*    </Typography>*/}
                                {/*</Button>*/}
                                {previewField}
                            </Grid>
                            <Grid item xs={12} sm={12} md={7} lg={7}>
                                <Typography className={classes.title} component="div" display="block" variant="h4">
                                    {
                                        translateText(
                                            header.language,
                                            book.bookName || '',
                                            book.bookNameMarathi || ''
                                        )
                                    }
                                </Typography>
                                <Grid container className={classes.cardsGrid}>
                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <Card className={classes.card} variant="elevation" elevation={0}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                                        <CalendarToday className={classes.cardIcon} color="primary" />
                                                    </Avatar>
                                                }
                                                title={
                                                    <Typography component="div" variant="body1" className={classes.cardTitle} color="primary">
                                                        {
                                                            translateText(
                                                                header.language,
                                                                "Publication Date",
                                                                "प्रकाशन तारीख"
                                                            )
                                                        }
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography component="div" variant="subtitle1" className={classes.cardSubTitle}>
                                                        {book.publicationDate}
                                                    </Typography>
                                                }
                                            />
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <Card className={classes.card} variant="elevation" elevation={0}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                                        <Language className={classes.cardIcon} color="primary" />
                                                    </Avatar>
                                                }
                                                title={
                                                    <Typography component="div" variant="body1" className={classes.cardTitle} color="primary">
                                                        {
                                                            translateText(
                                                                header.language,
                                                                "Book Language",
                                                                "भाषा"
                                                            )
                                                        }
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography component="div" variant="subtitle1" className={classes.cardSubTitle}>
                                                        {
                                                            translateText(
                                                                header.language,
                                                                book.bookLanguage || '',
                                                                book.bookLanguage === 'English' ? 'इंग्लिश' : 'मराठी'
                                                            )
                                                        }
                                                    </Typography>
                                                }
                                            />
                                        </Card>
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={4}>
                                        <Card className={classes.card} variant="elevation" elevation={0}>
                                            <CardHeader
                                                avatar={
                                                    <Avatar aria-label="recipe" className={classes.avatar}>
                                                        <MenuBook className={classes.cardIcon} color="primary" />
                                                    </Avatar>
                                                }
                                                title={
                                                    <Typography component="div" variant="body1" className={classes.cardTitle} color="primary">
                                                        {
                                                            translateText(
                                                                header.language,
                                                                "Pages",
                                                                "एकूण पाने"
                                                            )
                                                        }
                                                    </Typography>
                                                }
                                                subheader={
                                                    <Typography component="div" variant="subtitle1" className={classes.cardSubTitle}>
                                                        {book.bookPages}
                                                    </Typography>
                                                }
                                            />
                                        </Card>
                                    </Grid>
                                </Grid>
                                <Divider />
                                <Typography component="div" display="block" variant="body1" className={classes.description} paragraph>
                                    {
                                        translateText(
                                            header.language,
                                            book.briefDescription || '',
                                            book.briefDescriptionMarathi || ''
                                        )
                                    }
                                    {}
                                </Typography>
                                <Button onClick={() => showOrderForm(book.bookId || 0)} className={classes.contactUsButton} variant="outlined" startIcon={<LocalMall color="primary" />} color="primary">
                                    <Typography className={classes.imageButtonText} component="div" variant="body2" color="primary">
                                        {
                                            translateText(
                                                header.language,
                                                "Order Now",
                                                "ऑर्डर करा"
                                            )
                                        }
                                    </Typography>
                                </Button>
                            </Grid>
                            <Grid item md={1} lg={1}/>
                        </>
                    ) : (
                        <div className={classes.progress}>
                            <CircularProgress />
                        </div>
                    )
                }
            </Grid>
            <OrderFormContainer bookId={book.bookId || 0} bookName={book.bookName || ''} />
        </>
    )
};

export default BookDetails;
