import {AnyAction} from 'redux';

export const SHOW_ORDER_FORM = "SHOW_ORDER_FORM";
export const CLOSE_ORDER_FORM = "CLOSE_ORDER_FORM";

export const ShowOrderForm = (bookId: number): AnyAction => ({
  type: SHOW_ORDER_FORM,
  bookId,
});

export const CloseOrderForm = (): AnyAction => ({
  type: CLOSE_ORDER_FORM,
});
