import React from 'react';
import './App.scss';
import HeaderContainer from "./containers/header";
import BooksLandingContainer from './containers/books-landing';
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import {makeStyles, ThemeProvider} from '@material-ui/core';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import FooterContainer from "./containers/footer";
import Article_one from "./components/articles/article_one";
import Home from "./components/home/home";
import Article_two from "./components/articles/article_two";
import AboutContainer from "./containers/about";
import GurudevContainer from "./containers/gurudev";
import HistoricalSurveyContainer from "./containers/historical-survey";
import BookDetailsContainer from "./containers/book-details";
import GalleryLandingContainer from './containers/gallery';

export let apiURL = '';

declare module "@material-ui/core/styles/createBreakpoints" {
    interface BreakpointOverrides {
        view360: true;
        view411: true;
        view320: true;
        view375: true;
        view414: true;
        view768: true;
        view1024: true;
        view540: true;
        view280: true;
        view1920: true;
        view600: true;
    }
}

const theme = createMuiTheme({
    palette: {
        primary: {
            main: "#cf4723",
            dark: "#4a4a4a",
            light: "#de6b2f",
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
            view280: 280,
            view320: 320,
            view360: 360,
            view375: 375,
            view411: 411,
            view414: 414,
            view540: 540,
            view600: 600,
            view768: 768,
            view1024: 1024,
            view1920: 1920
        }
    },
    typography: {
        fontFamily: 'ArcherPro',
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        textAlign: "center",
        fontFamily: "ArcherPro, serif",
        display: "block",
        [theme.breakpoints.only("sm")]: {
            // minWidth: "900px !important",
        },
    }
}));

const App = () => {
    const classes = useStyles();
    return (
      <ThemeProvider theme={theme}>
        <div className={classes.root}>
            <Router>
                <HeaderContainer />
                <Switch>
                    <Route path="/books/:bookId" component={BookDetailsContainer} />
                    <Route path="/books">
                        <BooksLandingContainer />
                    </Route>
                    <Route path="/articles/1">
                        <Article_one/>
                    </Route>
                    <Route path="/articles/2">
                        <Article_two/>
                    </Route>
                    <Route path="/gallery">
                        <GalleryLandingContainer />
                    </Route>
                    <Route path="/about">
                        <AboutContainer />
                    </Route>
                    <Route path="/gurudev">
                        <GurudevContainer />
                    </Route>
                    <Route path="/historical_survey">
                        <HistoricalSurveyContainer />
                    </Route>
                    <Route path="/">
                        <Home />
                    </Route>
                </Switch>
                <FooterContainer/>
            </Router>
        </div>
      </ThemeProvider>
    );
};

export default App;
