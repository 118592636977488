import {Header, State} from "../store/state";
import {connect} from "react-redux";
import HistoricalSurveyComponent from "../components/historical_survey/historical_survey";

export interface HistoricalSurveyStateProps {
    header: Header;
}

const mapStateToProps = (state: State): HistoricalSurveyStateProps => ({
    header: state.header,
});

const HistoricalSurveyContainer = connect(
    mapStateToProps,
)(HistoricalSurveyComponent);

export default HistoricalSurveyContainer;
