import {State} from "../store/state";
import {connect} from "react-redux";
import GalleryLanding from "../components/gallery/landing/gallery";

export interface GalleryStateProps {
    language: string;
}

const mapStateToProps = (state: State): GalleryStateProps => ({
    language: state.header.language,
});

const GalleryLandingContainer = connect(
    mapStateToProps,
)(GalleryLanding);

export default GalleryLandingContainer;
