import {Header, State} from "../../store/state";
import {connect} from "react-redux";
import HeroComponent from "../../components/home/hero/hero";

export interface HeroStateProps {
    header: Header;
}

const mapStateToProps = (state: State): HeroStateProps => ({
    header: state.header,
});

const HeroContainer = connect(
    mapStateToProps,
)(HeroComponent);

export default HeroContainer;
