import React from 'react';
import {Grid, makeStyles, Typography} from "@material-ui/core";


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: theme.spacing(10),
    },
    title: {
        marginTop: theme.spacing(5),
        fontFamily: "ArcherProBold",
        fontSize: "2em",
        textAlign: "center",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2em",
        },
    },
    secondaryTitle: {
        marginTop: theme.spacing(5),
        color: theme.palette.primary.main,
        fontSize: "1.1em",
        fontFamily: "ArcherProBold",
        letterSpacing: "0.8px",
        lineHeight: "32px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
    },
    partText: {
        marginTop: theme.spacing(5),
        color: theme.palette.primary.dark,
        fontSize: "1em",
        fontFamily: "ArcherPro",
        letterSpacing: "0.8px",
        lineHeight: "32px",
        textAlign: "justify",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1em",
        },
    },
}));


const PrivacyPolicy = () => {
    const classes = useStyles();
    return (
        <>
            <Grid container className={classes.root}>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.title}>
                        Shreevidyaakhandmahayog Privacy Policy
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.partText}>
                        Shreevidyaakhandmahayog Privacy Policy Shreevidyaakhandmahayog ("us", "we", or "our") operates http://www.shreevidyamahayog.org (the "Site"). Shreevidyaakhandmahayog and shreevidyamahayog names may be used throughout the website, but refer to the same http://www.shreevidyamahayog.org website. This page informs you of our policies regarding the collection, use, and disclosure of Personal Information we receive from users of the Site.
                        <br/>
                        <br/>
                        At http://www.shreevidyamahayog.org we consider the privacy of our visitors to be extremely important. We use your Personal Information only for providing and improving the Site. By using the Site, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible at http://www.shreevidyamahayog.org                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Information Collection And Use
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        While using our Site, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you. Personally identifiable information may include, but is not limited to, your name, email address, postal address and phone number ("Personal Information").
                        <br/>
                        <br/>
                        We believe it is important to provide added protection for children online. We encourage parents and guardians to spend time online with their children to observe, participate in and/or monitor and guide their online activity. http://www.shreevidyamahayog.org does not knowingly collect any personally identifiable information from children under the age of 13. If a parent or guardian believes that http://www.shreevidyamahayog.org has in its database the personally-identifiable information of a child under the age of 13, please contact us immediately (see contact information below) and we will use our best efforts to promptly remove such information from our records.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Log Data
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data"). This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages, Internet Service Provider (ISP), referring/exit pages, the number of clicks, and other statistics. This information is used to analyze trends, administer the site, track user's movement around the site, and gather demographic information. IP addresses, demographic data, and other such information are not considered personal information and are not linked to any information that is personally identifiable.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Comments and Blog posts
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Our website will allow users to post original blogs or comments to a blog. Any such post will include either one or all of user first name, last name, or userid. No other personal information will be provided along with blogs or comments.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Cookies
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.
                        Like many sites, we use "cookies" to collect information about visitors' preferences, to record user-specific information on which pages the site visitor accesses or visits, and to personalize or customize our web page content based upon visitors' browser type or other information that the visitor sends via their browser.
                        You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site. More detailed information about cookie management with specific web browsers can be found at the browsers' respective websites.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Security
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        The security of your Personal Information is important to us, but remember that no method of transmission over the Internet, or method of electronic storage, is 100% secure. While we strive to use commercially acceptable means to protect your Personal Information, we cannot guarantee its absolute security.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Links To Other Sites
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Our Site may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party's site. We strongly advise you to review the Privacy Policy of every site you visit.
                        Shreevidyaakhandmahayog has no control over, and assumes no responsibility for the content, privacy policies, or practices of any third party sites or services.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Scope of Privacy Policy
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        This privacy policy applies only to http://www.shreevidyamahayog.org website and online activities associated with the website. This privacy policy is valid for visitors to our website and regarding information shared and/or collected there. This policy does not apply to any information collected offline or via channels other than this website.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Changes To This Privacy Policy
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        This Privacy Policy was last updated on: Friday, January 3, 2014. Shreevidyaakhandmahayog may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on the Site. You are advised to review this Privacy Policy periodically for any changes.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Consent
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        By using our website, you hereby consent to our privacy policy and agree to its terms.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Contact Us
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        If you have any questions or comments about this Privacy Policy, please contact us at admin@shreevidyamahayog.org.
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

export default PrivacyPolicy;
