import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    Button,
    Card,
    CardActionArea,
    CardActions,
    CardContent,
    CircularProgress,
    Grid,
    Typography,
    List,
    ListSubheader,
    ListItemText,
    ListItem,
    ListItemIcon,
    Divider,
} from '@material-ui/core';
import {ArrowForward, Language, CalendarToday, Search} from "@material-ui/icons";
import axios, {AxiosResponse} from "axios";
import {BookResponse} from "../../../types/books";
import {apiURL} from "../../../App";
import {Link} from "react-router-dom";
import {BooksLandingStateProps} from "../../../containers/books-landing";
import {translateText} from "../../../utils/utils";

const BooksLandingComponent = (
    props: BooksLandingStateProps,
) => {
    const {
        header,
    } = props;

    const useStyles = makeStyles((theme) => ({
        root: {
            flexGrow: 1,
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(5),
            minHeight: "100vh",
            minWidth: 375,
            [theme.breakpoints.down("xs")]: {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.between("xs", "sm")]: {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.only("sm")]: {
                marginTop: theme.spacing(10),
            },
            [theme.breakpoints.only("md")]: {
                marginTop: theme.spacing(10),
            },
        },
        logo: {
            width: 60,
            [theme.breakpoints.down("xs")]: {
                width: 40,
            },
            [theme.breakpoints.only("xs")]: {
                width: 40,
            },
            [theme.breakpoints.between("xs", "sm")]: {
                width: 40,
            },
            [theme.breakpoints.only("sm")]: {
                width: 40,
            },
        },
        paper: {
            height: 140,
            width: 100,
        },
        control: {
            padding: theme.spacing(2),
        },
        media: {
            height: 400,
            [theme.breakpoints.only("lg")]: {
                height: 400
            }
        },
        button: {
            border: "none",
            outline: 0,
        },
        title: {
            fontFamily: "ArcherProBold",
            color: theme.palette.primary.dark,
            marginTop: theme.spacing(2),
            fontSize: "2.5em",
            letterSpacing: '1px',
            [theme.breakpoints.down("xs")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "1.4em",
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "1.4em",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "2em",
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "2em",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "2em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "2em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "2.5em",
            },
        },
        card: {
            marginLeft: theme.spacing(4),
            marginRight: theme.spacing(4),
            marginBottom: theme.spacing(4),
            //height: 600,
            [theme.breakpoints.only("lg")]: {
                // height: 600
            }
        },
        cardTitle: {
            fontFamily: "ArcherPro",
            fontSize: "1.4rem",
            color: theme.palette.primary.main,
            textAlign: "center",
            wordBreak: "break-word",
        },
        cardContent: {
            //height: 120,
            [theme.breakpoints.only("lg")]: {
               // height: 120
            }
        },
        cardDescription: {
            fontFamily: "ArcherPro",
            fontSize: "1.1rem",
            marginTop: theme.spacing(3),
            color: theme.palette.primary.dark,
            [theme.breakpoints.only("lg")]: {
                fontSize: "0.9rem"
            }
        },
        cardAction: {
            // marginLeft: "auto",
            // [theme.breakpoints.down("xs")]: {
            //     margin: "auto",
            // },
            // [theme.breakpoints.only("xs")]: {
            //     margin: "auto",
            // },
            // [theme.breakpoints.between("xs", "sm")]: {
            //     margin: "auto",
            // },
            // [theme.breakpoints.only("sm")]: {
            //     margin: "auto",
            // },
        },
        filter: {
        },
        filterListDivider: {
            marginLeft: theme.spacing(1)
        },
        filterList: {
            width: '100%',
            maxWidth: 360,
            backgroundColor: theme.palette.background.paper,
        },
        filterTitle: {
            fontWeight: "bold",
            fontSize: "1rem",
            display: "inline-block",
            verticalAlign: "middle",
            marginLeft: theme.spacing(2),
            [theme.breakpoints.only("sm")]: {
                fontSize: "0.8rem"
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "0.6rem"
            }
        },
        filterItemText: {
            fontSize: "1em",
            [theme.breakpoints.only("sm")]: {
                fontSize: "0.8rem"
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "0.6rem"
            }
        },
        languageFilter: {
            marginTop: theme.spacing(2)
        },
        cardButton: {
            fontFamily: "ArcherProBold",
            [theme.breakpoints.down("xs")]: {
                textAlign: "center"
            },
            [theme.breakpoints.only("xs")]: {
                textAlign: "center"
            },
            [theme.breakpoints.between("xs", "sm")]: {
                textAlign: "center"
            },
            [theme.breakpoints.only("sm")]: {
                textAlign: "center"
            },
        },
        progress: {
            margin: "auto"
        },
        dividerGrid: {
            marginTop: theme.spacing(10),
            [theme.breakpoints.down("xs")]: {
                marginTop: theme.spacing(3),
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: theme.spacing(3),
            },
            [theme.breakpoints.between("xs", "sm")]: {
                marginTop: theme.spacing(3),
            },
            [theme.breakpoints.only("sm")]: {
                marginTop: theme.spacing(3),
            },
            [theme.breakpoints.only("md")]: {
                marginTop: theme.spacing(10),
            },
        },
    }));

    const [books, setBooks] = useState(Array<BookResponse>());

    useEffect(() => {
        axios.get(`${apiURL}/api/Books`, {
            headers: {
                "ApiKey": "SVAM",
            }
        }).then((result: AxiosResponse) => {
            if (result && result.data) {
                setBooks(result.data);
            }
        }).catch((error) => {
            console.log(error);
            setBooks([]);
        })
    }, []);

    const classes = useStyles();

    const [isMarathiSelected, setIsMarathiSelected] = useState(true);
    const [isEnglishSelected, setIsEnglishSelected] = useState(true);

    const filterBooksByLanguage = () => {
        let filteredBooks = [];
        if (isMarathiSelected) {
            const marathiBooks = books.filter(b => b.bookLanguage === "Marathi");
            books.forEach(b => console.log(b.bookLanguage));
            filteredBooks.push(...marathiBooks);
        }
        if (isEnglishSelected) {
            const englishBooks = books.filter(b => b.bookLanguage === "English");
            filteredBooks.push(...englishBooks);
        }
        setBooks(filteredBooks);
    };

    return (
        <>
            <Grid container className={classes.root} spacing={0}>
                <Grid item xs={12}>
                    <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg"/>
                    <Typography variant="h4" className={classes.title}>
                        {
                            translateText(
                                header.language,
                                "Books",
                                "पुस्तके"
                            )
                        }
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.dividerGrid} />

                <Grid className={classes.filter} item xs={12} sm={4} md={3} lg={2}>
                    <List subheader={<ListSubheader color="primary" style={{
                        textAlign: "left",
                    }} className={classes.languageFilter}>
                        <Language color="primary" style={{
                            verticalAlign: "middle"
                        }}/>
                        <Typography component="div" className={classes.filterTitle} color="primary">
                            {
                                translateText(
                                    header.language,
                                    "Language",
                                    "भाषा"
                                )
                            }
                        </Typography>
                    </ListSubheader>} className={classes.filterList}>
                        {/*<ListItem button>*/}
                        {/*    <ListItemIcon>*/}
                        {/*        <Checkbox*/}
                        {/*            onChange={() => {*/}
                        {/*                setIsEnglishSelected(!isEnglishSelected);*/}
                        {/*                filterBooksByLanguage();*/}
                        {/*            }}*/}
                        {/*            edge="end"*/}
                        {/*            color="primary"*/}
                        {/*            checked={isEnglishSelected}*/}
                        {/*        />*/}
                        {/*    </ListItemIcon>*/}
                        {/*    <ListItemText className={classes.filterItemText} primary={`English`} />*/}
                        {/*</ListItem>*/}
                        <ListItem button>
                            <ListItemIcon>
                                <Checkbox
                                    disabled={true}
                                    edge="end"
                                    color="primary"
                                    checked={isMarathiSelected}
                                />
                            </ListItemIcon>
                            <ListItemText className={classes.filterItemText} primary={
                                translateText(
                                    header.language,
                                    "Marathi",
                                    "मराठी"
                                )
                            } />
                        </ListItem>
                    </List>
                </Grid>



                <Grid item xs={12} sm={8} md={9} lg={10}>
                    <Grid container>
                        {
                            books && books.length > 0 ?
                                (
                                    books.map((book: BookResponse) => (
                                        <Grid item xs={12} sm={12} md={6} lg={4}>
                                            <Card className={classes.card} elevation={0}>
                                                <CardActionArea>
                                                    <img src={book.coverPageImage} className={classes.media} alt={book.bookName}/>
                                                    {/*<CardMedia*/}
                                                    {/*    className={classes.media}*/}
                                                    {/*    image={book.coverPageImage}*/}
                                                    {/*    title={book.bookName}*/}
                                                    {/*/>*/}
                                                    <CardContent className={classes.cardContent}>
                                                        <Typography gutterBottom variant="h5" component="h2" className={classes.cardTitle}>
                                                            {
                                                                translateText(
                                                                    header.language,
                                                                    book.bookName || '',
                                                                    book.bookNameMarathi || ''
                                                                )
                                                            }
                                                        </Typography>
                                                        <Typography variant="body2" component="p" className={classes.cardDescription} display="block">
                                                            {
                                                                translateText(
                                                                    header.language,
                                                                    book.shortDescription || '',
                                                                    book.shortDescriptionMarathi || ''
                                                                )
                                                            }
                                                        </Typography>
                                                    </CardContent>
                                                </CardActionArea>
                                                <CardActions style={{
                                                    textAlign: "center",
                                                    justifyContent: "center"
                                                }}>
                                                    <Link to={`/books/${book.bookId}`} style={{
                                                        textDecoration: "none",
                                                    }}>
                                                        <Button className={classes.cardAction} color="primary" variant="text" endIcon={<ArrowForward />}>
                                                            <Typography component="span" variant="body2" className={classes.cardButton}>
                                                                {
                                                                    translateText(
                                                                        header.language,
                                                                        "View",
                                                                        "अधिक माहिती"
                                                                    )
                                                                }
                                                            </Typography>
                                                        </Button>
                                                    </Link>
                                                </CardActions>
                                            </Card>
                                        </Grid>

                                    ))
                                ) : (
                                    <div className={classes.progress}>
                                        <CircularProgress />
                                    </div>
                                )
                        }
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
};

export default BooksLandingComponent;
