import {Header, State} from "../store/state";
import {Dispatch} from "redux";
import {setLanguage} from "../actions/header";
import {connect} from "react-redux";
import HeaderComponent from "../components/header/header";

export interface HeaderStateProps {
    state: Header;
}

export interface HeaderDispatchProps {
    setLanguage: (language: string) => void;
}

const mapStateToProps = (state: State): HeaderStateProps => ({
    state: state.header,
});

const mapDispatchToProps = (
    dispatch: Dispatch,
): HeaderDispatchProps => ({
    setLanguage: (language: string) => dispatch(setLanguage(language)),
});

// @ts-ignore
const HeaderContainer = connect(
    mapStateToProps,
    mapDispatchToProps,
)(HeaderComponent);

export default HeaderContainer;
