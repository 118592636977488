import React, {useEffect, useState} from 'react';
import './gallery.scss';
import axios, {AxiosResponse} from "axios";
import ContentLoader from 'react-content-loader'
import RotatingGallery from "../loader/gallery_loader";
import {
    Button,
    Grid,
    Typography,
} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ArrowForward} from "@material-ui/icons";
import {apiURL} from "../../../App";
import {GalleryStateProps} from "../../../containers/home/gallery";
import {translateText} from "../../../utils/utils";
import {Link} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
        marginTop: theme.spacing(15),
        backgroundColor: "#f7f5f2",
        [theme.breakpoints.down("md")]: {
            display: "none"
        },
    },
    logo: {
        marginBottom: theme.spacing(10),
    },
    imageGrid: {
        marginTop: theme.spacing(10),
    },
    button : {
        marginBottom: theme.spacing(10)
    },
    buttonText: {
        verticalAlign: "middle",
        fontFamily: "ArcherProBold",
        fontSize: "1.3rem",
        letterSpacing: "1px",
        textTransform: "none"
    },
}));

const GalleryComponent = (
    props: GalleryStateProps,
) => {
    const {
        header,
    } = props;

    interface ImageInfo {
        imageURL: string;
        className: string;
    }

    interface GalleryResponse {
        mediaId: string;
        mediaTypeId: number;
        mediaName: string;
        mediaURL: string;
        createdOn: string;
        createdBy: number;
        isActive: number;
        isDeleted: number;
    }

    const classes = useStyles();

    const getClassName = (index: number): string => {
        switch (index) {
            case 0: {
                return "hideLeft";
            }
            case 1: {
                return "prevLeftSecond";
            }
            case 2: {
                return "prev";
            }
            case 3: {
                return "selected";
            }
            case 4: {
                return "next";
            }
            case 5: {
                return "nextRightSecond";
            }
            case 6: {
                return "hideRight";
            }
            default: {
                return "next";
            }
        }
    };

    const convertResponse = (input: Array<GalleryResponse>): Array<ImageInfo> => {
        let response: Array<ImageInfo> = [];
        for(let i = 0; i < 7; i++) {
            response[i] = {
                imageURL: input[i].mediaURL,
                className: getClassName(i)
            }
        }
        return response;
    };

    const defaultImages: Array<ImageInfo> = [
        {
            imageURL: "http://d3o76blco64tu5.cloudfront.net/gallery/gallery-01.jpg",
            className: "hideLeft"
        },
        {
            imageURL: "http://d3o76blco64tu5.cloudfront.net/gallery/gallery-02.jpg",
            className: "prevLeftSecond"
        },
        {
            imageURL: "http://d3o76blco64tu5.cloudfront.net/gallery/gallery-03.jpg",
            className: "prev"
        },
        {
            imageURL: "http://d3o76blco64tu5.cloudfront.net/gallery/gallery-04.jpg",
            className: "selected"
        },
        {
            imageURL: "http://d3o76blco64tu5.cloudfront.net/gallery/gallery-05.jpg",
            className: "next"
        },
        {
            imageURL: "http://d3o76blco64tu5.cloudfront.net/gallery/gallery-06.jpg",
            className: "nextRightSecond"
        },
        {
            imageURL: "http://d3o76blco64tu5.cloudfront.net/gallery/gallery-07.jpg",
            className: "hideRight"
        }
    ];

    const emptyImages: Array<ImageInfo> = [];

    const [images, setImages] = useState(defaultImages);

    // useEffect(() => {
    //     axios.get(`${apiURL}/api/MediaByType/3`, {
    //         headers: {
    //             "ApiKey": "SVAM"
    //         }
    //     }).then((resp: AxiosResponse<Array<GalleryResponse>>) => {
    //         const responseImageInfo: Array<ImageInfo> = convertResponse(resp.data);
    //         console.log(responseImageInfo);
    //         setImages(responseImageInfo);
    //     }).catch(() => {
    //         setImages(defaultImages);
    //     })
    // }, []);

    return (
        <>
            <Grid container className={classes.root}>
                    {
                        images && images.length > 0 ? (
                            <>
                                <Grid component="div" item xs={12} className={classes.imageGrid}>
                                    <div id="carousel">
                                        {
                                            images.map((image: ImageInfo) => (
                                                <div className={image.className}>
                                                    <img src={image.imageURL} alt="Gallery image" />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </Grid>
                                <Grid component="div" item xs={12}>
                                    <Link to="/gallery" style={{
                                        textDecoration: "none",
                                    }}>
                                        <Button color="primary" variant="text" endIcon={<ArrowForward />} className={classes.button}>
                                            <Typography component="span" variant="body2" className={classes.buttonText}>
                                                {
                                                    translateText(
                                                        header.language,
                                                        "View Gallery",
                                                        "गॅलरी"
                                                    )
                                                }
                                            </Typography>
                                        </Button>
                                    </Link>
                                </Grid>
                            </>
                        ) : (
                            <RotatingGallery />
                        )
                    }
            </Grid>
        </>
    )
};

export default GalleryComponent;
