import {Header, State} from "../../store/state";
import {connect} from "react-redux";
import Promotions from "../../components/home/promotions/promotions";

export interface PromotionsStateProps {
    header: Header;
}

export interface PromotionsDispatchProps {}

const mapStateToProps = (
    state: State,
): PromotionsStateProps => ({
    header: state.header,
});

const PromotionsContainer = connect(
    mapStateToProps,
)(Promotions);

export default PromotionsContainer;
