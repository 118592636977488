import {State} from "../../store/state";
import {connect} from "react-redux";
import Message from "../../components/home/message/message";

export interface MessageStateProps {
    language: string;
}

const mapStateToProps = (state: State): MessageStateProps => ({
    language: state.header.language,
});

const MessageContainer = connect(
    mapStateToProps,
)(Message);

export default MessageContainer;
