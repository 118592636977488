import React, {useState} from 'react';
import {Button, Grid, LinearProgress, makeStyles, TextField, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: theme.spacing(10),
        textAlign: "center"
    },
    title: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        fontFamily: "ArcherProBold",
        fontSize: "2em",
        textAlign: "center",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2em",
        },
    },
    textFieldGrid: {
        marginTop: theme.spacing(5),
    },
    buttonText: {
        fontFamily: "ArcherProBold",
        fontSize: "1em",
        letterSpacing: "1px",
        textTransform: "none",
    },
    textField: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
        width: "100%",
        [theme.breakpoints.down("md")]: {
            width: "90%",
        },
    },
    progress: {
        marginTop: theme.spacing(2),
        textAlign: "center"
    }
}));

const ContactUs = () => {
    const classes = useStyles();

    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [query, setQuery] = useState('');

    const [isSendingRequest, setIsSendingRequest] = useState(false);

    const [error, setError] = useState('');

    return (
        <>
            <Grid container className={classes.root}>
                <Grid xs={12}>
                    <TextField className={classes.textField} label="First Name" required id="first_name" value={firstName} onChange={(e: any) => setFirstName(e.target.value)} variant="outlined" />
                </Grid>
                <Grid xs={12}>
                    <TextField className={classes.textField} required id="last_name" label="Last Name" value={lastName} onChange={(e: any) => setLastName(e.target.value)} variant="outlined" />
                </Grid>
                <Grid xs={12}>
                    <TextField className={classes.textField} required id="email" label="Email" value={email} onChange={(e: any) => setEmail(e.target.value)} variant="outlined" />
                </Grid>
                <Grid xs={12}>
                    <TextField className={classes.textField} id="mobile" label="Phone No." value={phone} onChange={(e: any) => setPhone(e.target.value)} variant="outlined" />
                </Grid>
                <Grid item xs={12} className={classes.textFieldGrid}>
                    <TextField
                        id="outlined-multiline-static"
                        label="How can we help you?"
                        multiline
                        rows={8}
                        value={query}
                        variant="outlined"
                        className={classes.textField}
                    />
                </Grid>
                {
                    error != '' ? (
                      <Grid xs={12}>
                          <Typography color="primary">
                              Error: {error}
                          </Typography>
                      </Grid>
                    ) : ''
                }
                <Grid item xs={12} className={classes.textFieldGrid}>
                    <Button variant="outlined" color="primary">
                        <Typography component="span" variant="body1" className={classes.buttonText}>
                            Submit
                        </Typography>
                    </Button>
                </Grid>
                {
                    isSendingRequest ? (
                      <Grid xs={12} className={classes.progress}>
                          <LinearProgress color="primary" style={{
                              width: "100%",
                          }} />
                      </Grid>
                    ) : ''
                }
            </Grid>
        </>
    );
};

export default ContactUs;
