import {Header, State} from "../../store/state";
import {connect} from "react-redux";
import VideosComponent from "../../components/home/videos/videos";

export interface VideosStateProps {
    header: Header;
}

const mapStateToProps = (
    state: State,
): VideosStateProps => ({
    header: state.header,
});

const VideosContainer = connect(
    mapStateToProps,
)(VideosComponent);

export default VideosContainer;
