import React from 'react';
import {Grid, makeStyles, Typography} from "@material-ui/core";
import {HistoricalSurveyStateProps} from "../../../containers/home/historical-survey";
import {translateText} from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
        marginTop: theme.spacing(20),
        marginBottom: theme.spacing(20),
        minWidth: 375,
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(5),
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(5),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(5),
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: theme.spacing(0),
            marginBottom: theme.spacing(5),
        },
        [theme.breakpoints.between("sm", "md")]: {
            marginTop: theme.spacing(10),
            marginBottom: theme.spacing(5),
        }
    },
    imageGrid: {
        verticalAlign: "middle",
        margin: "auto",
    },
    gurudevImage: {
        width: "100%",
        height: "auto",
        // [theme.breakpoints.down("xs")]: {
        //     height: 300,
        // },
        // [theme.breakpoints.only("xs")]: {
        //     height: 300,
        // },
        // [theme.breakpoints.between("xs", "sm")]: {
        //     height: 300,
        // },
        [theme.breakpoints.only("sm")]: {
            width: "70%",
        }
        // [theme.breakpoints.between("sm", "md")]: {
        //     height: 300,
        // },
        // [theme.breakpoints.only("md")]: {
        //     height: 400,
        // },
        // [theme.breakpoints.between("md", "lg")]: {
        //     height: 400,
        // },
        // [theme.breakpoints.only("lg")]: {
        //     height: 450,
        // },
    },
    gurudevTextGrid: {
        textAlign: "left",
        verticalAlign: "middle",
        margin: "auto",
        [theme.breakpoints.down("xs")]: {
            textAlign: "center",
            padding: 10,
        },
        [theme.breakpoints.only("xs")]: {
            textAlign: "center",
            padding: 10,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            textAlign: "center",
            padding: 10,
        },
        [theme.breakpoints.only("sm")]: {
            textAlign: "center !important",
            padding: 10,
        },
        [theme.breakpoints.between("sm", "md")]: {
            textAlign: "left",
        },
    },
    title: {
        marginTop: theme.spacing(3),
        fontFamily: "ArcherPro",
        fontSize: "2.3em",
        letterSpacing: '1px',
        color: theme.palette.primary.light,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2.3em",
        },
    },
    text: {
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        fontFamily: "ArcherPro",
        fontSize: "1.1em",
        letterSpacing: '1px',
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.1em",
        },
    },
    buttonText: {
        fontFamily: "ArcherProBold",
        fontSize: "1.3em",
        letterSpacing: "1px",
        textTransform: "none",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.8em",
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "0.8em",
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "0.8em",
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.3em",
        },
    },
    link: {
        textDecoration: "none",
        color: theme.palette.primary.light,
    },
    gridDivider: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(4),
        },
    },
    showOnSmall: {
        display: "none",
        [theme.breakpoints.down("xs")]: {
            display: "block",
        },
        [theme.breakpoints.only("xs")]: {
            display: "block",
        },
        [theme.breakpoints.only("sm")]: {
            display: "block",
        },
    },
    hideOnSmall: {
        display: "block",
        verticalAlign: "middle",
        margin: "auto",
        [theme.breakpoints.down("xs")]: {
            display: "none",
        },
        [theme.breakpoints.only("xs")]: {
            display: "none",
        },
        [theme.breakpoints.only("sm")]: {
            display: "none",
        },
    },
}));

const HistoricalSurvey = (
    props: HistoricalSurveyStateProps,
) => {
    const classes = useStyles();
    const {
        header,
    } = props;

    return (
        <>
            <Grid component="div" container className={classes.root}>
                <Grid component="div" item sm ={1} md={1} lg={1} />
                <Grid component="div" item xs={12} sm={12} md={5} lg={5} className={classes.imageGrid}>
                    <img src="../assets/img/gurudev-6.jpg" className={classes.gurudevImage} />
                </Grid>
                <Grid component="div" item sm={1} md={1} />
                <Grid component="div" item xs={12} sm={10} md={4} lg={4} className={classes.gurudevTextGrid}>
                    <Typography component="div" variant="h6" className={classes.title}>
                        {
                            translateText(
                                header.language,
                                'Shree Gurudev',
                                'श्री गुरुदेव'
                            )
                        }
                    </Typography>
                    <Typography component="div" variant="h6" className={classes.text}>
                        {
                            translateText(
                                header.language,
                                `
                                Prof. Shriniwas Shamrao Katkar, reverently known as Shree Gurudev among his admirers, devotees & disciples is a practicing
                        mahayogi and a great philosopher. As a spiritual guru, he has been guiding eligible spiritual seekers, irrespective of their class,
                        caste, language, and gender in the practical discipline of Shreevidyamahayog. Shree Gurudev is an eternal source of divine spiritual inspiration
                        and a true modern-day sage.
                                `,
                                `
                                प्रा. श्रीनिवास शामराव काटकर, त्यांच्या प्रशंसक, भक्त आणि शिष्य यांच्यात श्रद्धेने श्रीगुरुदेव म्हणून ओळखले जातात, ते एक महायोगी आणि एक महान तत्वज्ञ आहेत. आध्यात्मिक गुरु म्हणून ते श्रीविद्यामहायोगाच्या व्यावहारिक शास्त्रामध्ये पात्र, अध्यात्मिक साधकांचा वर्ग, जात, भाषा आणि लिंग विचारात न घेता मार्गदर्शन करीत आहेत. श्री गुरुदेव हे दिव्य आध्यात्मिक प्रेरणा आणि खर्‍या आधुनिक युगातील शाश्वत स्त्रोत आहेत.
                                `
                            )
                        }
                    </Typography>
                    <a href={`/gurudev`} color="primary" className={classes.link}>
                        <Typography component="span" variant="body2" className={classes.buttonText}>
                            {
                                translateText(
                                    header.language,
                                    'Read More',
                                    'अधिक माहिती '
                                )
                            }
                        </Typography>
                    </a>
                </Grid>
                <Grid component="div" sm={1} item md={1} lg={1} />

                <Grid component="div" item xs={12} className={classes.gridDivider} />

                <Grid component="div" item xs={12} className={classes.showOnSmall}>
                    <img src="http://d3o76blco64tu5.cloudfront.net/misc/Hero-banner.jpg" className={classes.gurudevImage} />
                </Grid>

                <Grid component="div" item sm={1} md={1} />
                <Grid component="div" item xs={12} sm={10} md={4} lg={4} className={classes.gurudevTextGrid}>
                    <Typography component="div" variant="h6" className={classes.title}>
                        {
                            translateText(
                                header.language,
                                'Shreevidya Akhandmahayog',
                                'श्रीविद्याअखंडमहायोग'
                            )
                        }
                    </Typography>
                    <Typography component="div" variant="h6" className={classes.text}>
                        {
                            translateText(
                                header.language,
                                'Shreevidyaakhandmahayog is a spiritual discipline originated from Lord Shri Dakshinamurty (Lord Shiva in the role of Vishwamahaguru, the preceptor of the whole Cosmos) to bring the highest spiritual realization for the most ardent spiritual aspirant.',
                                'सच्चिदानंदस्वरूप  परमार्थतत्वाचा सर्वोच्च ,परिपूर्ण साक्षात्कार प्राप्त व्हावा अशा तीव्र अभिप्सेने युक्त असलेल्या जिज्ञासू  साधकांसाठी भगवान परमशिव श्रीदक्षिणामूर्तिंनी “श्रीविद्याअखंडमहायोग” ही साधनापद्धती सर्वप्रथम प्रचारात आणली.'
                            )
                        }
                    </Typography>
                    <a href={`/historical_survey`} color="primary" className={classes.link}>
                        <Typography component="span" variant="body2" className={classes.buttonText}>
                            {
                                translateText(
                                    header.language,
                                    'Read More',
                                    'अधिक माहिती '
                                )
                            }
                        </Typography>
                    </a>
                </Grid>
                <Grid component="div" item sm={1} md={1} />
                <Grid component="div" item xs={12} sm={12} md={5} lg={5} className={classes.hideOnSmall}>
                    <img src="http://d3o76blco64tu5.cloudfront.net/misc/Hero-banner.jpg" className={classes.gurudevImage} />
                </Grid>
                <Grid component="div" item md={1} />

            </Grid>
        </>
    );
};


export default HistoricalSurvey;
