import React, {useMemo, useState} from "react";
import {
  Box,
  Button,
  Dialog,
  Grid,
  LinearProgress,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import {ArrowForward, CloseRounded} from "@material-ui/icons";
// @ts-ignore
// @ts-ignore
import countryList from 'react-select-country-list';
import axios from "axios";
import {OrderFormDispatchProps, OrderFormStateProps} from "../../containers/order-form";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    padding: 30,
    textAlign: "center",
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: "50%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  orderTitleGrid: {
    textAlign: "center"
  },
  title: {
    fontFamily: "ArcherProBold",
    color: theme.palette.primary.dark,
    fontSize: "2em",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
      padding: 10,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1.2em",
      padding: 10,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1.2em",
      padding: 10,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1.3em",
      padding: 10,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1.3em",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.5em",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "1.7em",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "2em",
    },
  },
  bookTitle: {
    fontFamily: "ArcherProBold",
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: "1.5em",
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: "1em",
      padding: 10,
    },
    [theme.breakpoints.only("xs")]: {
      fontSize: "1em",
      padding: 10,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      fontSize: "1em",
      padding: 10,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: "1em",
      padding: 10,
    },
    [theme.breakpoints.between("sm", "md")]: {
      fontSize: "1em",
    },
    [theme.breakpoints.only("md")]: {
      fontSize: "1.2em",
    },
    [theme.breakpoints.between("md", "lg")]: {
      fontSize: "1.2em",
    },
    [theme.breakpoints.only("lg")]: {
      fontSize: "1.5em",
    },
  },
  orderButton: {
    width: 250,
    padding: theme.spacing(1.3),
    marginTop: theme.spacing(5),
    fontSize: "1em",
    color: theme.palette.primary.main,
  },
  orderButtonText: {
    fontFamily: "ArcherProBold",
    fontSize: "1em",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      fontSize: "0.8em",
    }
  },
  progress: {
    marginTop: theme.spacing(2),
    textAlign: "center"
  },
  logo: {
    marginBottom: theme.spacing(2),
    width: 40,
    [theme.breakpoints.down("xs")]: {
      width: 40,
    },
    [theme.breakpoints.only("xs")]: {
      width: 40,
    },
    [theme.breakpoints.between("xs", "sm")]: {
      width: 40,
    },
    [theme.breakpoints.only("sm")]: {
      width: 40,
    },
  },
}));

type OrderFormStateDispatchProps = OrderFormStateProps & OrderFormDispatchProps;

const OrderForm = (props: OrderFormStateDispatchProps) => {
  const {
    closeOrderForm,
    show,
    bookId,
    bookName,
  } = props;

  const classes = useStyles();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address, setAddress] = useState('');
  const [zip, setZip] = useState('');
  const [county, setCountry] = useState('')
  const options = useMemo(() => countryList().getData(), [])
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');

  const [isSendingRequest, setIsSendingRequest] = useState(false);

  const [error, setError] = useState('');

  const changeCountryHandler = (e: any) => {
    setCountry(e.target.value);
  };

  const validateEmail = (mail: string): boolean => {
    return /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(mail);
  }

  const handleSubmit = () => {
    if (firstName == '' || firstName.length > 120) {
      setError(`First Name can't be empty nor it can exceed 120 characters`);
      return;
    }
    if (lastName == '' || lastName.length > 120) {
      setError(`Last Name can't be empty nor it can exceed 120 characters`);
      return;
    }
    if (address == '' || address.length > 500) {
      setError(`Address can't be empty nor it can exceed 500 characters`);
      return;
    }
    if (zip == '' || zip.length > 10) {
      setError(`Zip can't be empty nor it can exceed 10 characters`);
      return;
    }
    if (county == '') {
      setError(`Country can't be empty`);
      return;
    }
    if (email == '') {
      setError(`Email can't be empty`);
      return;
    }
    if (!validateEmail(email)) {
      setError(`Email must be in correct format`);
      return;
    }
    if (phone == '' || phone.length > 20) {
      setError(`Phone can't be empty nor it can exceed 15 characters`);
      return;
    }

    setError('');
    setIsSendingRequest(true);

    axios.post("/api/Bookreservations", {
      'firstName': firstName,
      'lastName': lastName,
      'address': address,
      'zip': zip,
      'country': county,
      'email': email,
      'phone': phone,
      'requestedBookId': bookId,
    }).then((result: any) => {
      setError('');
      setIsSendingRequest(false);
      closeOrderForm();
    }).catch((e: any) => {
      console.log('Error reservation', e);
      setIsSendingRequest(false);
      setError(`Failed to submit request. Please try again`);
    })
  };

  return (
    <Dialog fullWidth={true} style={{
      minWidth: "375px"
    }} onClose={() => closeOrderForm()} aria-labelledby="simple-dialog-title" open={show} maxWidth="md">
      <Box display="flex" alignItems="center" style={{
        padding: "20px"
      }}>
        <Box flexGrow={1} style={{
          textAlign: "center"
        }} >
          <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg" alt="divider"/>
          <Typography className={classes.title}>
            Order Book
          </Typography>
        </Box>
        <Box>
          <IconButton onClick={() => closeOrderForm()} color="primary">
            <CloseRounded />
          </IconButton>
        </Box>
      </Box>
      <Grid container className={classes.root}>
        <Grid xs={12}>
          <Typography className={classes.bookTitle}>
            {bookName}
          </Typography>
        </Grid>
        <Grid xs={12}>
          <TextField className={classes.textField} label="First Name" variant="outlined" required id="first_name" value={firstName} onChange={(e: any) => setFirstName(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <TextField className={classes.textField} required id="last_name" variant="outlined" label="Last Name" value={lastName} onChange={(e: any) => setLastName(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <TextField className={classes.textField} required id="address" label="Address" variant="outlined" value={address} onChange={(e: any) => setAddress(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <TextField className={classes.textField} required id="zip" label="Zip" variant="outlined" value={zip} onChange={(e: any) => setZip(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <TextField
            id="standard-select-country"
            select
            label="Select Country"
            value={county}
            className={classes.textField}
            onChange={changeCountryHandler}
            style={{
              textAlign: "left"
            }}
            variant="outlined"
          >
            {
              options.map((option: any) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>
        <Grid xs={12}>
          <TextField className={classes.textField} required id="email" variant="outlined" label="Email" value={email} onChange={(e: any) => setEmail(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <TextField className={classes.textField} id="mobile" variant="outlined" label="Phone No." value={phone} onChange={(e: any) => setPhone(e.target.value)} />
        </Grid>
        <Grid xs={12}>
          <TextField
            className={classes.textField}
            id="more-info"
            label="Additional Info"
            multiline
            rows={4}
            variant="outlined"
          />
        </Grid>
        {
          error != '' ? (
            <Grid xs={12}>
              <Typography color="primary">
                Error: {error}
              </Typography>
            </Grid>
          ) : ''
        }
        <Grid xs={12}>
          <Button onClick={() => handleSubmit()} className={classes.orderButton} variant="contained" endIcon={<ArrowForward style={{
            color: "white"
          }}/>} color="primary">
            <Typography className={classes.orderButtonText} component="div" variant="body2">
              Submit
            </Typography>
          </Button>
        </Grid>
        {
          isSendingRequest ? (
            <Grid xs={12} className={classes.progress}>
              <LinearProgress color="primary" style={{
                width: "100%",
              }} />
            </Grid>
          ) : ''
        }
      </Grid>
    </Dialog>
  )
};

export default OrderForm;
