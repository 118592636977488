import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Button, Grid, Typography} from '@material-ui/core';
import {ArrowForward} from "@material-ui/icons";
import {BlogsStateProps} from "../../../containers/home/blogs";
import {translateText} from "../../../utils/utils";
import {Link} from "react-router-dom";

const BlogsComponent = (
    props: BlogsStateProps,
) => {
    const {
        header,
    } = props;

    interface BlogInfo {
        title: string;
        description: string;
        link: string;
    }

    const useStyles = makeStyles((theme) => ({
        root: {
            backgroundColor: "#f7f5f2",
            minWidth: 375,
        },
        logo: {
            marginTop: theme.spacing(10),
            width: 60,
            [theme.breakpoints.down("xs")]: {
                width: 40,
            },
            [theme.breakpoints.only("xs")]: {
                width: 40,
            },
            [theme.breakpoints.between("xs", "sm")]: {
                width: 40,
            },
            [theme.breakpoints.only("sm")]: {
                width: 40,
            },
        },
        title: {
            marginTop: theme.spacing(3),
            fontFamily: "ArcherProBold",
            fontSize: "2.5em",
            letterSpacing: '1px',
            color: theme.palette.primary.dark,
            [theme.breakpoints.down("xs")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "1.4em",
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "1.4em",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "2em",
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "2em",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "2em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "2em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "2.5em",
            },
        },
        articleGrid: {
            marginTop: theme.spacing(15),
            [theme.breakpoints.down("xs")]: {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.only("xs")]: {
                marginTop: theme.spacing(5),
            },
            [theme.breakpoints.between("xs", "sm")]: {
                marginTop: theme.spacing(7),
            },
            [theme.breakpoints.only("sm")]: {
                marginTop: theme.spacing(7),
            },
        },
        blogTitle: {
            fontSize: "1.7em",
            letterSpacing: "1px",
            color: theme.palette.primary.dark,
            fontFamily: "ArcherProBold",
            [theme.breakpoints.down("xs")]: {
                fontSize: "1em",
                padding: 5,
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "1em",
                padding: 5,
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "1em",
                padding: 5,
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "1.2em",
                padding: 5,
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "1.2em",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "1.3em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "1.3em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "1.7em",
            },
        },
        description: {
            marginTop: theme.spacing(3),
            padding: 20,
            letterSpacing: "1px",
            fontSize: '1.3em',
            color: theme.palette.primary.dark,
            textAlign: "justify",
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "0.9em",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "1.1em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "1.3em",
            },
        },
        button : {
            marginTop: theme.spacing(3)
        },
        buttonText: {
            verticalAlign: "middle",
            fontWeight: "bold",
            fontFamily: "ArcherProBold",
            fontSize: "1.1em",
            letterSpacing: "1px",
            [theme.breakpoints.down("xs")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.only("xs")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.between("xs", "sm")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.only("sm")]: {
                fontSize: "0.8em",
            },
            [theme.breakpoints.between("sm", "md")]: {
                fontSize: "0.9em",
            },
            [theme.breakpoints.only("md")]: {
                fontSize: "0.9em",
            },
            [theme.breakpoints.between("md", "lg")]: {
                fontSize: "1em",
            },
            [theme.breakpoints.only("lg")]: {
                fontSize: "1.1em",
            },
        },
        dividerGrid: {
            marginBottom: theme.spacing(10)
        }
    }));

    const blogs: Array<BlogInfo> = [
        {
            description: translateText(
                header.language,
                "What are the basic tenets of Vedic Hinduism? What are the paths to reach the Divine? Learn about Hinduism as H.H. Shri GurudevComponent Shriniwasji explains the basic principles of Vedic Hinduism and its differences from other religions in this short excerpt from his discussion with young Americans",
                "वैदिक हिंदू धर्माची मूलभूत तत्वे कोणती आहेत? देवापर्यंत पोहोचण्याचा मार्ग कोणता आहे? हिंदू धर्मांबद्दल एच. एच. श्री. गुरुदेव श्रीनिवासजी यांनी तरुण अमेरिकेशी केलेल्या चर्चेतून वैदिक हिंदू धर्माची मूलभूत तत्त्वे आणि इतर धर्मांतील भिन्नता याबद्दलचे स्पष्टीकरण दिले आहे ते जाणून घ्या."
            ),
            title: translateText(
                header.language,
                "Vedic‌ ‌Hinduism‌",
                "वैदिक हिंदू धर्म",
            ),
            link: "/articles/1",
        },
        {
            description: translateText(
                header.language,
                "Every person in this world wants to be happy. The pursuit of happiness is a common thread that connects all people. Yet, do we truly understand the meaning of happiness? What are the characteristics of a happy person? Explore this article as H.H. Shri GurudevComponent Shriniwasji explains the true meaning of happiness and the real goal of a spiritual seeker through this lucid dialogue with spiritual aspirants.",
                "या जगातील प्रत्येक व्यक्तीला आनंदी रहाण्याची इच्छा आहे. आनंदाचा पाठपुरावा हा एक सामान्य धागा आहे जो सर्व लोकांना जोडतो. तरीही, आनंदाचा अर्थ आपल्याला खरोखर समजला आहे का? आनंदी व्यक्तीची वैशिष्ट्ये कोणती? एच. एच. श्री गुरुदेव श्रीनिवासजी आनंदाचा खरा अर्थ आणि अध्यात्मिक इच्छुकांसमवेत या भव्य संवादातून आध्यात्मिक साधकाचे खरे ध्येय स्पष्ट करतात म्हणून हा लेख जाणून घ्या."
            ),
            title: translateText(
                header.language,
                "True Happiness – Goal of a Spiritual Aspirant",
                "खरा आनंद - आध्यात्मिक आकांक्षाचे ध्येय"
            ),
            link: "/articles/2",
        }
    ];

    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg" alt="divider"/>
                    <Typography variant="h5" component="div" className={classes.title}>
                        {
                            translateText(
                                header.language,
                                "Articles",
                                "लेख"
                            )
                        }
                    </Typography>
                </Grid>
                <Grid item md={2} />
                {
                    blogs.map((blog, i) => (
                        <Grid item xs={12} sm={12} md={4} className={classes.articleGrid}>
                            <Typography component="div" variant="h6" className={classes.blogTitle}>
                                {blog.title}
                            </Typography>
                            <Typography component="div" variant="body1" className={classes.description}>
                                {blog.description}
                            </Typography>
                            <Link to={blog.link} style={{
                                textDecoration: "none",
                            }}>
                                <Button color="primary" variant="text" endIcon={<ArrowForward />} className={classes.button}>
                                    <Typography component="span" variant="body2" className={classes.buttonText}>
                                        {
                                            translateText(
                                                header.language,
                                                "Read",
                                                "लेख"
                                            )
                                        }
                                    </Typography>
                                </Button>
                            </Link>
                        </Grid>
                    ))
                }
                <Grid item md={2} />
                <Grid item xs={12} className={classes.dividerGrid} />
            </Grid>
        </>
    )
};

export default BlogsComponent;
