import home from 'reducers/home';
import {combineReducers} from "redux";
import orderForm from "./order-form";
import header from "./header";

const rootReducer = combineReducers({
    home: home,
    orderForm: orderForm,
    header: header,
});

export default rootReducer;
