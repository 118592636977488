import {connect} from "react-redux";
import {Header, State} from '../store/state';
import {ShowOrderForm} from '../actions/order-form';
import {Dispatch} from "redux";
import BookDetails from "../components/books/details/details";

export interface BookDetailsInputProps {
  bookId: number;
}

export interface BookDetailsStateProps {
  header: Header;
}

export interface BookDetailsDispatchProps {
  showOrderForm: (bookId: number) => void;
}

const mapStateToProps = (state: State): BookDetailsStateProps => ({
  header: state.header,
});

const mapDispatchToProps = (dispatch: Dispatch): BookDetailsDispatchProps => ({
  showOrderForm: (bookId: number) => dispatch(ShowOrderForm(bookId)),
});

const BookDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BookDetails);

export default BookDetailsContainer;
