import React, {useState} from 'react';
import {Button, Grid, makeStyles, Typography} from "@material-ui/core";
import {ArrowForward} from "@material-ui/icons";
import {ArticleInfo, ArticlePart} from "./types";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(5),
        minWidth: 375,
    },
    grid: {
        textAlign: "justify",
        marginBottom: theme.spacing(6)
    },
    image: {
        width: "100%",
        height: 700,
        [theme.breakpoints.down("xs")]: {
            height: 200,
        },
        [theme.breakpoints.only("xs")]: {
            height: 200,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            height: 230,
        },
        [theme.breakpoints.only("sm")]: {
            height: 400,
        },
        [theme.breakpoints.between("sm", "md")]: {
            height: 400,
        },
        [theme.breakpoints.only("md")]: {
            height: 450,
        },
        [theme.breakpoints.between("md", "lg")]: {
            height: 500,
        },
        [theme.breakpoints.only("lg")]: {
            height: 700,
        },
    },
    title: {
        marginTop: theme.spacing(5),
        fontFamily: "ArcherProBold",
        fontSize: "2em",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2em",
        },
    },
    publishDateButton: {
        marginTop: theme.spacing(2),
    },
    publishDateButtonText: {
        fontSize: '1.1em',
        color: theme.palette.primary.dark,
        fontFamily: "ArcherPro",
        textAlign: "center",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.1em",
        },
    },
    aboutText: {
        fontSize: '1.2em',
        color: theme.palette.primary.dark,
        fontStyle: "italic",
        textAlign:"center",
        marginTop: theme.spacing(2),
        fontFamily: "ArcherPro",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.2em",
        },
    },
    partText: {
        marginTop: theme.spacing(5),
        color: theme.palette.primary.dark,
        fontSize: "1.3em",
        fontFamily: "ArcherPro",
        letterSpacing: "0.8px",
        lineHeight: "32px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.3em",
        },
    },
    endImage: {
        marginBottom: theme.spacing(6)
    },
    logoGrid: {
        margin: "auto"
    },
    logo: {
        width: 60,
        [theme.breakpoints.down("xs")]: {
            width: 40,
        },
        [theme.breakpoints.only("xs")]: {
            width: 40,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: 40,
        },
        [theme.breakpoints.only("sm")]: {
            width: 40,
        },
    },
    dividerGrid: {
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("xs")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            marginTop: theme.spacing(3),
        },
        [theme.breakpoints.only("sm")]: {
            marginTop: theme.spacing(5),
        },
        [theme.breakpoints.only("md")]: {
            marginTop: theme.spacing(5),
        },
    },
    next: {
        color: theme.palette.primary.dark,
        fontFamily: "ArcherPro",
        fontSize: "1.6em",
        marginTop: theme.spacing(5),
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
    },
    nextButton: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    nextTitle: {
        color: theme.palette.primary.main,
        fontFamily: "ArcherProBold",
        fontSize: "1.2em",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.1em",
        },
    }
}));

const Article_one = () => {
    const parts: Array<ArticlePart> = [
        {
            content: ""
        },
    ];

    const articleInfo: ArticleInfo = {
        title: "Vedic‌ ‌Hinduism‌",
        publishDate: "December 30, 2020",
        about: "Adapted from His Holiness Shrigurudev Shriniwasji’s dialogue with Young Americans",
        parts: parts,
    };

    const classes = useStyles();
    const [article, setArticle] = useState(articleInfo);

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.logoGrid}>
                    <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg"/>
                    <Typography className={classes.title} variant="h4" component="div" color="primary">
                        {article.title}
                    </Typography>
                    <Typography component="div" variant="subtitle1" className={classes.publishDateButtonText}>
                        {article.publishDate}
                    </Typography>
                </Grid>

                <Grid item xs={12} className={classes.dividerGrid} />

                <Grid item xs={1} sm={1} md={1} lg={3} />
                <Grid item xs={10} sm={10} md={10} lg={6} className={classes.grid}>
                    {
                        article.about ? (
                            <Typography className={classes.aboutText} component="div" variant="body1">
                                {article.about}
                            </Typography>
                        ): ""
                    }

                    <Typography className={classes.partText} component="div" variant="body1">
                        Let us look at the present picture of the religious world. There are two main groups of religions:
                        <br />
                        <br />
                        <em><b>Abrahamic Religions:</b></em> &nbsp; These are associated with Abraham and this group includes Christianity, Judaism and Islam
                        <br />
                        <em><b>Non-Abrahamic Religions:</b></em> &nbsp; This group consists of Hinduism, Buddhism, Jainism, Confucians and others.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        The Abrahamic religions such as Christianity, Judaism and Islam have some common principles.
                        Similarly, among non-abrahamic religions the Hindus, Buddhists, and Jains have some common religious tenets.
                        One of the common tenets is that the soul is immortal, and that it has no beginning or end. It is the human body that perishes.
                        The Soul is never created but gets re-born again and again.
                        While the present Soul is only a middle step there have been many past incarnations of the soul and there will be many incarnations or rebirths of the Soul in the future .
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        The Soul will continue to travel until it gets liberated from the cycle of birth and death.
                        The liberation of Soul from the cycle of birth and death is known as `Moksha’.
                        This concept of `Moksha’ is common to Buddhists, Jains, and Hindus.
                        However, Buddhists and Jains are Non-Vedic while only Hindus are Vedic.
                        The term `Hindu’ should be specifically used as Vedic, the followers of Vedas.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        There is one more peculiarity amongst these people. Some of them treat the soul as impermanent yet immortal.
                        For Buddhists, the soul as different from the body is impermanent. But for Jains and Vedic Hindus, the soul is permanent and immortal.
                        The cycle of birth and death continues until one attains liberation i.e., `Moksha’.
                        This is not our first birth, but we have had many births in the past and we will have many births in the future. Whatever actions we do while travelling through the cycle of birth and death are called `Karma’. We have to reap the fruit of those actions or Karma. It is this karma, in a sense, that controls the whole universe.
                        Whatever happens in this universe happens due to the karma done by the soul.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        How does the principle of Karma work? The Buddhists and Jains state that the karma is self-activating principle and that there is no need of other external agency.
                        So, they do not believe in a permanent God. For Vedic Hindus, God exists and it is the God who activates the principle of Karma.
                        Karma itself is an inert principle. It is the God, the supreme Divine, who controls the Karma of individuals. As per the quality of the Karma, God makes all individuals travel through the `Sansar’ i.e., the cycle birth and death. Thus, the eternal character of the Soul and its actions determine the Karma. This can be treated as one of the peculiarities of Non-Abrahamic religions i.e., Buddhism, Jainism and Hinduism.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        However, there is difference between Buddhists, Jains, and the Vedic Hindus.
                        Buddhists and Jains do not accept the eternal God. They do accept the divine condition of the Soul but deny that there is an ever-existing God who activates the Karmas of the individuals and assigns them birth as per the quality of karma.
                        The Vedic Hindus, on the contrary, essentially believe in the existence of God who is omniscient, omnipotent, and omnipresent. This is the most distinguishing characteristic feature which sets Hinduism apart from Abrahamic religions. According to Abrahamic religions, God exists only in heaven and He is transcendent and not immanent.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        Christians, especially Catholics, believe in the Trinity - God, the Son of God, and the Holy Spirit.
                        Their belief is that God resides only in Heaven and exists within us only as a Holy Spirit.
                        This is not the concept of Vedic Hindus. For Hindus, God himself or herself is within us.
                        In Hinduism, God is both a masculine and feminine principle. The Divine is both the Father and the Mother.
                        God is Father because he has infinite knowledge and God is Mother because she is selfless infinite lover.
                        So, Hindus believe in God and Goddess.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        Hinduism is a tolerant religion. Christians first arrived in the state of Kerala, India around 1st Century A.D.
                        They were welcomed here and not persecuted. India was a secular country right from ancient times.
                        The existence and spread of Abrahamic religions was not opposed with physical power by Hindus in India.
                        This begs the question as to why didn’t Hindus fight? It is because in India the Hindus, Buddhists, and Jains believed that each and every way towards the divine leads to the realization of one aspect of the divine.
                        In this sense, every religion contains some amount of partial truth.
                        If someone follows his religion sincerely, then that person will reach the realization of partial truth.
                        In this sense, all religions are true, but no religion should claim that it reveals the complete truth.
                        It was this conviction that made Indians tolerant and because of this tolerant attitude of Hindus the spread of Christianity and Islam was not opposed.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        Hinduism describes three ways to reach the Divine - Samipya, Sadrushya, and Sayujya.
                        These are Sanskrit words. Samipya means to attain proximity with the Divine.
                        A person can also be similar to the Divine, which is termed as Sadrushya.
                        A person can also merge with the Divine i.e., be one with God.
                        This is termed as Sayujya. Each of these ways is spiritual and each of the states is considered spiritual realization.
                        Everything depends upon a person’s choice.
                        A person may stop at Samipya state, or Sadrushya state, or choose to merge themselves with the divine.
                    </Typography>

                    <Typography className={classes.partText} component="div" variant="body1">
                        There are three steps to reach the divine,
                        <br/>
                        <br/>
                        <em>Strong and sincere aspiration.</em>
                        <br/>
                        <em>Continuous thinking and contemplation on the spiritual ideal.</em>
                        <br/>
                        <em>Continuous meditation of the spiritual goal.</em>
                        <br/>
                        <br/>
                        All these should take place while leading a normal life. One should not think that the normal life is an obstacle to this process. If one follows these steps, he will surely reach the Divine.
                    </Typography>

                    <div>
                        <Typography component="div" className={classes.next}>
                            Next
                        </Typography>
                        <Button href="/articles/2" endIcon={<ArrowForward color="primary" />} className={classes.nextButton}>
                            <Typography component="div" className={classes.nextTitle}>
                                True Happiness – Goal of a Spiritual Aspirant
                            </Typography>
                        </Button>
                    </div>
                </Grid>
                <Grid item xs={1} sm={1} md={1} lg={3} />
            </Grid>
        </>
    );
};

export default Article_one;
