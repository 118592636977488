import React, {useEffect, useState} from 'react';
import {Grid, makeStyles, Typography} from "@material-ui/core";
import axios, {AxiosResponse} from "axios";
import {Message as MessageResponse} from "../../../types/message";
import {MessageStateProps} from "../../../containers/home/message";
import {translateText} from "../../../utils/utils";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 0,
        backgroundColor: "#f7f5f2",
        minWidth: 375,
    },
    logo: {
        marginTop: theme.spacing(10),
        width: 60,
        [theme.breakpoints.down("xs")]: {
            width: 40,
        },
        [theme.breakpoints.only("xs")]: {
            width: 40,
        },
        [theme.breakpoints.between("xs", "sm")]: {
            width: 40,
        },
        [theme.breakpoints.only("sm")]: {
            width: 40,
        },
    },
    title: {
        marginTop: theme.spacing(3),
        fontFamily: "ArcherProBold",
        fontSize: "2.5em",
        letterSpacing: '1px',
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "2em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2.5em",
        },
    },
    message: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        padding: 5,
        letterSpacing: "1px",
        fontSize: '1.5em',
        color: theme.palette.primary.dark,
        [theme.breakpoints.down("xs")]: {
            fontSize: "1em",
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.2em",
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.4em",
            marginTop: theme.spacing(4),
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.4em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1.5em",
        },
    }
}));

const Message = (props: MessageStateProps) => {
    const {
        language,
    } = props;

    const classes = useStyles();

    const defaultMessage: MessageResponse = {
        message: 'The Spiritual teacher, The Sadguru  guides us to moksha prapti or liberation. Whenever we address Him as ‘Sadguru mauli’, it is not just a verbal formality. Sri GurudevComponent is really our parent/nurturer , our  ‘Sadguru mauli’. His divine personality is endowed with (exhibits) a supernatural blend of divine knowledge of Satchidanand Parashiv, the Universal Father and divine love of Satchidananda Paramshivshakti, the Universal mother. Although limitless knowledge and deep unconditional love appear to be contrary to one another to our gross understanding, the wealth of these divine virtues incarnates in its entireness in Sri GurudevComponent. In this sthool world Sadguru is the saguna sakaar incarnation of  divine father Parashiva, the abode of divine knowledge as well as  that of divine mother, the abode of Divine love.'
    };
    const [message, setMessage] = useState(defaultMessage);

    useEffect(() => {
        axios.get(`/api/Messages`, {
            headers: {
                "ApiKey": "SVAM",
            }
        }).then((result: AxiosResponse<Array<MessageResponse>>) => {
            setMessage(result.data[Math.floor(Math.random() * result.data.length)]);
        }).catch((error) => {
            console.log(error);
        })
    }, []);

    return (
        <>
            <Grid container className={classes.root}>
                <Grid item xs={12}>
                    <img className={classes.logo} src="http://d3o76blco64tu5.cloudfront.net/misc/flower-01.svg" alt="divider"/>
                    <Typography variant="h5" component="div" className={classes.title}>
                        {
                            translateText(
                                language,
                                "Message From Gurudev",
                                "श्री गुरुदेवांचा संदेश"
                            )
                        }
                    </Typography>
                </Grid>
                <Grid item md={1} lg={2} />
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Typography component="div" variant="h6" className={classes.message}>
                        {
                            translateText(
                                language,
                                'Oh, Our divine Sadguru,' +
                                'Let our intellect be a lighting-torch of Your divine Wisdom.',
                            'निरंजनसुखाकारवृत्तीशं चंद्रशेखरम् ।\n' +
                                'महायोगेश्वरं वंदे सद्गुरुं देशिकाग्रणीम् ।। ० ।।\n'
                            )
                        }
                    </Typography>
                    <Typography component="div" variant="h6" className={classes.message}>
                        {
                            translateText(
                                language,
                                'Oh, Our divine Sadguru,\n' +
                                'Let our mind be the strongest vehicle of Your omnipotent Will.',
                                'आम्हा सर्वांची बुद्धि, हे दिव्य सद्गुरो, आपल्याच दिव्य प्रज्ञानाने आलोकित होवो,\n' +
                                'आम्हा सर्वांचे मन, हे दिव्य सद्गुरो, आपल्याच सत्यसंकल्पास धारण करो,\n' +
                                'आम्हा सर्वांची शरीरे, इंद्रिये, प्राण, हे दिव्य सद्गुरो, आपल्याच दिव्य, अनिर्बंध प्राणशक्तीने अनुप्राणित होवोत.\n' +
                                'हीच आपल्या चरणी सविनय प्रार्थना ॐ'
                            )
                        }
                    </Typography>
                    <Typography component="div" variant="h6" className={classes.message}>
                        {
                            translateText(
                                language,
                                'Oh, Our divine Sadguru,' +
                                'Let our body, senses and vital vibrate through Your indomitable power of Action. This is our humble prayer at Your feet Divine.',
                            ''
                            )
                        }
                    </Typography>
                </Grid>
                <Grid item md={1} lg={2} />
            </Grid>
        </>
    )
};

export default Message;
