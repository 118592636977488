import React from 'react';
import {Grid, makeStyles, Typography} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: theme.spacing(10),
    },
    title: {
        marginTop: theme.spacing(5),
        fontFamily: "ArcherProBold",
        fontSize: "2em",
        textAlign: "center",
        color: theme.palette.primary.main,
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1.2em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1.3em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "2em",
        },
    },
    secondaryTitle: {
        marginTop: theme.spacing(5),
        color: theme.palette.primary.main,
        fontSize: "1.1em",
        fontFamily: "ArcherProBold",
        letterSpacing: "0.8px",
        lineHeight: "32px",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
    },
    partText: {
        marginTop: theme.spacing(5),
        color: theme.palette.primary.dark,
        fontSize: "1em",
        fontFamily: "ArcherPro",
        letterSpacing: "0.8px",
        lineHeight: "32px",
        textAlign: "justify",
        [theme.breakpoints.down("xs")]: {
            fontSize: "0.9em",
        },
        [theme.breakpoints.only("xs")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("xs", "sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("sm")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("sm", "md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("md")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.between("md", "lg")]: {
            fontSize: "1em",
        },
        [theme.breakpoints.only("lg")]: {
            fontSize: "1em",
        },
    },
}));

const TermsAndCondition = () => {
    const classes = useStyles();

    return (
        <>
            <Grid container className={classes.root}>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.title}>
                        Terms & Conditions
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.partText}>
                        Shreevidyaakhandmahayog ("us", "we", or "our") operates http://www.shreevidyamahayog.org (the "Site"). Shreevidyaakhandmahayog and shreevidyamahayog names may be used throughout the website, but refer to the same http://www.shreevidyamahayog.org website. This page informs you of the terms and conditions that govern the use of this website.
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        This web page represents a legal document that serves as our Terms of Conditions and it governs the legal terms of our website, www.shreevidyamahayog.org, sub-domains, and any associated web-based and mobile applications (collectively, "Website"), as owned and operated by Shreevidyaakhandmahayog. These Terms and Conditions, along with our Privacy Policy, and other posted guidelines within our Website, collectively "Legal Terms", constitute the entire and only agreement between you and Shreevidyaakhandmahayog, and supersede all other agreements, representations, warranties, and understandings with respect to our Website and the subject matter contained herein.
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        By using this website, you accept these terms and conditions in full and without reservation. If you disagree with these terms and conditions or any part of these terms and conditions, you must not use this website.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Definitions
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        The terms "us" or "we" or "our" refers to Shreevidyaakhandmahayog, the owner of the Website.
                        <br/>
                        A "Visitor" is someone who merely browses our Website, but has not registered as Member.
                        <br/>
                        A "Member" is an individual that has registered with us to use our Service.
                        <br/>
                        An “Admin” is one or more individuals who administer the content on our website
                        <br/>
                        Our "Service" represents the collective functionality and features as offered through our Website to our Members.
                        <br/>
                        A "User" is a collective identifier that refers to either a Visitor or a Member.
                        <br/>
                        “You” refers to the “User” as defined above
                        <br/>
                        All text, information, graphics, audio, video, and data offered through our Website are collectively known as "Content".
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        License to use website
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Unless otherwise stated, Shreevidyaakhandmahayog owns the intellectual property rights to materials used on www.shreevidyamahayog.org. Subject to the license below, all these intellectual property rights are reserved.
                        <br/>
                        You may view, download for caching purposes only, and print pages, files or other content from the website for your own personal use, subject to the restrictions set out below and elsewhere in these terms and conditions.
                        <br/>
                        You must not (unless you have explicit written permission from Shreevidyaakhandmahayog):
                        <br/>
                        ·      republish material from this website in neither print nor digital media or documents (including republication on another website);
                        <br/>
                        ·      sell, rent, or sub-license material from the website;
                        <br/>
                        ·      show any material from the website in public;
                        <br/>
                        ·      reproduce, duplicate, copy, or otherwise exploit material on this website for a commercial purpose;
                        <br/>
                        ·      edit or otherwise modify any material on the website;
                        <br/>
                        ·      redistribute material from this website - except for content specifically and expressly made available for redistribution; or
                        <br/>
                        ·      republish or reproduce any part of this website through the use of iframes or screenscrapers.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Acceptable use
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        You must not use this website in any way that causes, or may cause, damage to the website or impairment of the availability or accessibility of www.shreevidyamahayog.org or in any way which is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity.
                        <br/>
                        You must not use this website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software.
                        <br/>
                        You must not conduct any systematic or automated data collection activities on or in relation to this website without Shreevidyaakhandmahayog’s express written consent. This includes:
                        <br/>
                        ·      scraping
                        <br/>
                        ·      data mining
                        <br/>
                        ·      data extraction
                        <br/>
                        ·      data harvesting
                        <br/>
                        ·      'framing' (iframes)
                        <br/>
                        ·      Article 'Spinning'
                        <br/>
                        You must not use this website or any part of it to transmit or send unsolicited commercial communications.
                        <br/>
                        You must not use this website for any purposes related to marketing without the express written consent of Shreevidyaakhandmahayog.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Restricted access
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Access to certain areas of this website is restricted. www.shreevidyamahayog.org reserves the right to restrict access to certain areas of this website, or at our discretion, this entire website. www.shreevidyamahayog.org may change or modify this policy without notice.
                        <br/>
                        If www.shreevidyamahayog.org provides you with a user ID and password to enable you to access restricted areas of this website or other content or services, you must ensure that the user ID and password are kept confidential. You alone are responsible for your password and user ID security.
                        <br/>
                        www.shreevidyamahayog.org may disable your user ID and password at www.YourSite.com's sole discretion without notice or explanation.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Code of Conduct for User Content
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        In these terms and conditions, “your user content” means material (including without limitation text, images, audio material, video material and audio-visual material) that you submit to this website, for whatever purpose.
                        <br/>
                        You grant to Shreevidyaakhandmahayog a worldwide, irrevocable, non-exclusive, royalty-free license to use, reproduce, adapt, publish, translate, and distribute your user content in any existing or future media. You also grant to Shreevidyaakhandmahayog the right to sub-license these rights, and the right to bring an action for infringement of these rights.
                        <br/>
                        Your user content must not be illegal or unlawful, must not infringe any third party's legal rights, and must not be capable of giving rise to legal action whether against you or Shreevidyaakhandmahayog or a third party (in each case under any applicable law).
                        <br/>
                        You must not submit any user content to the website that is or has ever been the subject of any threatened or actual legal proceedings or other similar complaint.
                        <br/>
                        www.shreevidyamahayog.org reserves the right to edit or remove any material submitted to this website, or stored on the servers of www.shreevidyamahayog.org, or hosted or published upon this website.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Code of Conduct for User Contributions
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Your contributions must not infringe anyone else’s rights, including copyright. Your contributions must not plagiarize or infringe the rights of any other person including any copyright, database, trade mark, trade secret, privacy, publicity, personal or proprietary rights of any kind. Therefore with regard to existing articles, news reports, music, video clips or other copyright material you will generally need the explicit permission of the relevant copyright owners
                        <br/>
                        Your contributions must not contain unlawful or objectionable content nor involve disruptive, offensive or abusive behavior. Please be respectful and civil to other members, even if you disagree with them. Material that is unlawful, harassing, threatening, defamatory, obscene, offensive, abusive, hateful, inflammatory, profane, racially, sexually or religiously offensive or otherwise objectionable or unlawful is not acceptable and will be removed from the website.
                        <br/>
                        Your contributions must not contain unsuitable or irrelevant website addresses or URLs. Links to pornography, commercial or other inappropriate content will be deleted.
                        <br/>
                        Your contributions must not promote illegal or anti-social behaviour. Contributions must not contain violent or sexually explicit material or advocate, promote or assist any unlawful act such as (by way of example only) terrorist acts, copyright infringement or computer misuse.
                        <br/>
                        Your contributions must not misrepresent their origins. Contributions and/or user names may not be used to impersonate any other person, to misrepresent your identity or affiliation with any person or to give the impression that they emanate from us, if that is not the case.
                        <br/>
                        Your contributions must not involve any flooding, spamming or advertising. Flooding is when the same comment is posted over and over in the same forum; spamming is the posting of the same comment (or very similar) to more than one forum. All advertisements, chain letters, pyramid schemes, junk mail and any other forms of commercial solicitation are prohibited and will be deleted.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Breach of Code of Conduct for User Content or Contributions
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        It will be our sole decision as to whether there has been a breach of this Code of Conduct. We have the right (but not the obligation) to edit, refuse to post, or to remove any contribution in whole or in part that we deem to be in breach of the Code of Conduct. Where we believe there is a breach of the Code of Conduct, we may take whatever action we feel is appropriate in the circumstances.
                        <br/>
                        Failure to comply with this Code of Conduct constitutes a material breach of the Website Terms, which could result in us taking any of the following actions:
                        <br/>
                        • issuing a warning to you;
                        <br/>
                        • immediate, temporary, or permanent withdrawal of any contribution or of your right to use our websites, including deletion of any personal profile;
                        <br/>
                        • prohibit access to restricted areas of website;
                        <br/>
                        • legal proceedings against you for reimbursement of all losses and costs on an indemnity basis (including, but not limited to, reasonable administrative and legal costs) resulting from the breach and/or further legal action against you; and/or
                        <br/>
                        • disclosure of such information to law enforcement authorities or other relevant third parties as we reasonably feel is necessary.
                        <br/>
                        We exclude liability for actions taken in response to breaches of this Code of Conduct. The responses described in this policy are not limited, and we may take any other action we reasonably deem appropriate.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        No Warranties
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        This website is provided “as is” without any representations or warranties, express or implied. www.shreevidyamahayog.org makes no representations or warranties in relation to this website or the information and materials provided on this website.
                        <br/>
                        Without prejudice to the generality of the foregoing paragraph, www.shreevidyamahayog.org does not warrant that this website will be constantly available or available at all.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Limitations of liability
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Shreevidyaakhandmahayog will not be liable to you (whether under the law of contact, the law of torts or otherwise) in relation to the contents of, or use of, or otherwise in connection with, this website:
                        <br/>
                        ·      to the extent that the website is provided free-of-charge, for any direct loss;
                        <br/>
                        ·      for any indirect, special or consequential loss;
                        <br/>
                        ·      for any direct or indirect impact to physical or mental health, or
                        <br/>
                        ·      for any business losses, loss of revenue, income, profits or anticipated savings, loss of contracts or business relationships, loss of reputation or goodwill, or loss or corruption of information or data.
                        <br/>
                        These limitations of liability apply even if Shreevidyaakhandmahayog has been expressly advised of the potential loss.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Reasonableness
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        By using this website, you agree that the exclusions and limitations of liability set out in this website disclaimer are reasonable.
                        If you do not think they are reasonable, you must not use this website.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Other parties
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        You agree that you will not bring any claim personally against Shreevidyaakhandmahayog’s officers or employees in respect of any losses you suffer in connection with the website.

                        Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability set out in this website disclaimer will protect Shreevidyaakhandmahayog’s officers, employees, agents, subsidiaries, successors, assigns, and sub-contractors.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Unenforceable provisions
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        If any provision of this website disclaimer is, or is found to be, unenforceable under applicable law, that will not affect the enforceability of the other provisions of this website disclaimer.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Indemnity
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        You hereby indemnify Shreevidyaakhandmahayog and undertake to keep Shreevidyaakhandmahayog indemnified against any losses, damages, costs, liabilities, and expenses (including without limitation legal expenses and any amounts paid by Shreevidyaakhandmahayog to a third party in settlement of a claim or dispute on the advice of Shreevidyaakhandmahayog’s legal advisers) incurred or suffered by Shreevidyaakhandmahayog arising out of any breach by you of any provision of these terms and conditions, or arising out of any claim that you have breached any provision of these terms and conditions.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Breaches of these terms and conditions
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        Without prejudice to www.shreevidyamahayog.org’s other rights under these terms and conditions, if you breach these terms and conditions in any way, Shreevidyaakhandmahayog may take such action as Shreevidyaakhandmahayog deems appropriate to deal with the breach, including suspending your access to the website, prohibiting you from accessing the website, blocking computers using your IP address from accessing the website, contacting your internet service provider to request that they block your access to the website and/or bringing court proceedings against you.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Changes To Terms and Conditions
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        These Terms and Conditions were last updated on: Saturday, 06 Feb, 2021. Shreevidyaakhandmahayog may update these Terms and Conditions from time to time. We will notify you of any changes by posting the new Terms and Conditions on the Site. You are advised to review these Terms and Conditions periodically for any changes.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Consent
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        By using our website, you hereby consent to our privacy policy and agree to its terms and conditions.
                    </Typography>
                </Grid>
                <Grid component="div" item xs={12}>
                    <Typography component="div" className={classes.secondaryTitle}>
                        Contact Us
                    </Typography>
                    <Typography component="div" className={classes.partText}>
                        If you have any questions or comments about these Terms and Conditions, please contact us at admin@shreevidyamahayog.org
                    </Typography>
                </Grid>
            </Grid>
        </>
    )
};

export default TermsAndCondition;
